import React, { useEffect, useState } from 'react'
import axiosInstance from '../axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FaCarAlt, FaDollarSign, FaEye, FaFileAlt, FaHome } from 'react-icons/fa';
import { Accordion, Alert, Badge, Button, Card, InputGroup, Modal, Table } from 'react-bootstrap';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import HouseSchema from '../Schema/HouseSchema';
import InputField from '../ReusableFormiks/InputField';
import { setTargetLoan } from '../Common/redux/systemLookups';
import { PulseLoader } from 'react-spinners';
import { singleHomePrice } from '../Common/modules/NewFiveCSums';


function House({ successnotify, errornotify }) {
    const { data } = useSelector(store => store.customer);
    const { serverIP } = useSelector(store => store.allsettings);
    const { targetLoan } = useSelector(store => store.systemLookups);
    const dispatch = useDispatch();
    const { loanId } = useParams();

    const [buildingmaterials, setbuildingmaterials] = useState([]);
    const [buildingtypes, setbuildingtypes] = useState([]);
    const [floorlocations, setfloorlocations] = useState([]);
    const [fromasphalts, setfromasphalts] = useState([]);

    const [locations, setlocations] = useState([]);
    const [neighbours, setneighbours] = useState([]);
    const [physicalappearances, setphysicalappearances] = useState([]);
    const [setbacks, setsetbacks] = useState([]);
    const [homeuses, sethomeuses] = useState([]);
    const [roadtosite, setroadtosite] = useState([]);

    const [regions, setRegions] = useState([]);

    const [editingRecord, setEditingRecord] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);

    const [gpModal, setGpModal] = useState(false);
    const [gpid, setGpid] = useState(null);

    const [targetRecord, setTargetRecord] = useState({})
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [show, setShow] = useState(false);
    const baseStyle = "form-control block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 py-1 px-1 text-base"

    useEffect(() => {
        getAllLookupFiles();
    }, []);


    const getAllLookupFiles = async () => {
        console.log("The lookups for home")
        // 'ddlhomebuildingmaterial',
        let ddlhomebuildingmaterial = await axiosInstance.get(`${serverIP}/buildingmaterial/buildingmaterial`);
        setbuildingmaterials(ddlhomebuildingmaterial.data);

        // 'ddlhomebuildingtype',
        let ddlhomebuildingtype = await axiosInstance.get(`${serverIP}/buildingtype/buildingtype`);
        setbuildingtypes(ddlhomebuildingtype.data);

        // 'ddlhomefloorlocation',
        let ddlhomefloorlocation = await axiosInstance.get(`${serverIP}/floorlocation/floorlocation`);
        setfloorlocations(ddlhomefloorlocation.data);

        // 'ddlhomefromasphalt',
        let ddlhomefromasphalt = await axiosInstance.get(`${serverIP}/fromasphalt/fromasphalt`);
        setfromasphalts(ddlhomefromasphalt.data);

        // 'ddlhomelocation',
        let ddlhomelocation = await axiosInstance.get(`${serverIP}/homelocation/homelocation`);
        setlocations(ddlhomelocation.data);

        // 'ddlhomeneighbour',
        let ddlhomeneighbour = await axiosInstance.get(`${serverIP}/neighbour/neighbour`);
        setneighbours(ddlhomeneighbour.data);

        // 'ddlhomephysicalappearance',
        let ddlhomephysicalappearance = await axiosInstance.get(`${serverIP}/physicalappearance/physicalappearance`);
        setphysicalappearances(ddlhomephysicalappearance.data);

        // 'ddlhomesetback',
        let ddlhomesetback = await axiosInstance.get(`${serverIP}/setback/setback`);
        setsetbacks(ddlhomesetback.data);

        // 'ddlhomeuse',
        let ddlhomeuse = await axiosInstance.get(`${serverIP}/homeuse/homeuse`);
        sethomeuses(ddlhomeuse.data);

        // 'ddlhomeaccessroadtosite',
        let ddlhomeaccessroadtosite = await axiosInstance.get(`${serverIP}/accessroadtosite/accessroadtosite`);
        setroadtosite(ddlhomeaccessroadtosite.data);

        // 'regions',
        let regions = await axiosInstance.get(`${serverIP}/regions/regions/activeregions`);
        setRegions(regions.data);
    }

    if (isLoading) {
        return (
            <div className="h-screen flex items-center justify-center bg-gray-100">
                <div className="flex flex-col gap-3 items-center justify-center">
                    <PulseLoader size={30} color="orange" loading="true" />
                    <div style={{ color: '#FFA500' }}>Loading ...</div>
                </div>
            </div>
        );
    }

    const initialValues = {
        buildingmaterial: '',
        buildingtype: '',
        floorlocation: '',

        fromasphalt: '',
        location: '',
        neighbour: '',

        physicalappearance: '',
        setback: '',
        homeuse: '',

        uniquenum: '',
        homearea: '',
        totalhomearea: '',

        serialnum: '',
        sanctionAddress: '',
        houseno: '',

        amDescription: '',
        isFenceIsolation: false,
        accessroadtosite: '',
        regionId: '',
        loan: loanId
        // regionId, zoneId, woredaId
    };

    const handleSubmit = (values, { resetForm }) => {
        console.log('handle submit')
        console.log(values)
        if (editingRecord) {
            // Update existing user
            axiosInstance
                .patch(`${serverIP}/collateral_home/collateralhomes/${targetRecord.id}`, {
                    buildingmaterial: values.buildingmaterial,
                    buildingtype: values.buildingtype,
                    floorlocation: values.floorlocation,

                    fromasphalt: values.fromasphalt,
                    location: values.location,
                    neighbour: values.neighbour,

                    physicalappearance: values.physicalappearance,
                    setback: values.setback,
                    homeuse: values.homeuse,

                    uniquenum: values.uniquenum,
                    homearea: values.homearea,
                    totalhomearea: values.totalhomearea,

                    serialnum: values.serialnum,
                    sanctionAddress: values.sanctionAddress,
                    houseno: values.houseno,

                    amDescription: values.amDescription,
                    isFenceIsolation: values.isFenceIsolation,
                    accessroadtosite: values.accessroadtosite,

                    regionId: values.regionId,
                })
                .then(res => {
                    successnotify();
                    setShowAddModal(false);
                    getLoan();
                    console.log(res.data)
                })
                .catch(err => {
                    errornotify();
                    console.log(err)
                });
            getLoan();

            setEditingRecord(false);
            setShowAddModal(false)

        } else {
            axiosInstance
                .post(`${serverIP}/collateral_home/collateralhomes`, {
                    buildingmaterial: values.buildingmaterial,
                    buildingtype: values.buildingtype,
                    floorlocation: values.floorlocation,

                    fromasphalt: values.fromasphalt,
                    location: values.location,
                    neighbour: values.neighbour,

                    physicalappearance: values.physicalappearance,
                    setback: values.setback,
                    homeuse: values.homeuse,

                    uniquenum: values.uniquenum,
                    homearea: values.homearea,
                    totalhomearea: values.totalhomearea,

                    serialnum: values.serialnum,
                    sanctionAddress: values.sanctionAddress,
                    houseno: values.houseno,

                    amDescription: values.amDescription,
                    isFenceIsolation: values.isFenceIsolation,
                    accessroadtosite: values.accessroadtosite,

                    regionId: values.regionId,
                    loan: loanId,
                })
                .then(res => {
                    successnotify();
                    setShowAddModal(false);
                    // setIsBtnSaveClicked(false)
                    getLoan();
                    console.log(res.data)
                })
                .catch(err => {
                    errornotify();
                    console.log(err)
                });
            getLoan();
            setShowAddModal(false)

        }
        setShowAddModal(false)
        resetForm();
    };

    const getLoan = () => {
        axiosInstance
            .get(`${serverIP}/loan/loans/${targetLoan.id}`)
            .then(res => {
                dispatch(
                    setTargetLoan({
                        targetLoan: res.data
                    })
                );
            })
            .catch(err => {
                console.log(err);
            });
    };

    const loadData = async homeId => {
        try {
            const ddlCarChecklist = await axiosInstance.get(`${serverIP}/ddlhouse/ddlhouses`);
            const transformedArray = ddlCarChecklist?.data.map(item => ({
                parent: homeId,
                user: data.id,
                amDesc: item.amName,
                checkListId: item.id,
                isMandatory: item.isMandatory,
                markValue: item.markValue
            }));

            // First POST request
            const insert_CheckLists = await axiosInstance.post(
                `${serverIP}/home_commonfiles/homefiles/bulkinsert`,
                transformedArray
            );

            getLoan();
        } catch (error) {
            console.error('Error:', error);
        } finally {
            // setIsLoading(false);
        }
    };


    const reLoadData = homeId => {
        axiosInstance
            .delete(`${serverIP}/home_commonfiles/homefiles/deletebyparent/${homeId}`)
            .then(res => {
                getLoan();
            })
            .catch(err => {
                console.log(err);
            });
    };


    const deleteRecord = () => {
        axiosInstance
            .delete(`${serverIP}/collateral_home/collateralhomes/${targetRecord.id}`)
            .then(res => {
                setModalDelete(false);
                getLoan();
            })
            .catch(err => {
                console.log(err);
            });
    };



    const changeGP = () => {
        axiosInstance
            .patch(`${serverIP}/collateral_home/collateralhomes/${targetRecord.id}`, {
                homegp: gpid
            })
            .then(res => {
                successnotify();
                setGpModal(false);
                getLoan();
            })
            .catch(err => {
                console.log(err);
            });
    };


    function handleUpload(uploaditem) {
        if (!file) {
            errornotify();
            return;
        }

        const fd = new FormData();
        fd.append('file', file);
        fd.append('fileType', file.type);
        fd.append('fileUrl', file);
        fd.append('isUploaded', true);

        axiosInstance
            .patch(`${serverIP}/home_commonfiles/homefiles/${uploaditem.id}`, fd, {
                headers: {
                    'Custom-Header': 'value'
                }
            })
            .then(res => {
                getLoan();
                successnotify();
            })
            .catch(err => {
                errornotify();
            });
    }


    const handleDeleteFile = item => {
        axiosInstance
            .patch(`${serverIP}/home_commonfiles/homefiles/${item.id}`, {
                isUploaded: false
            })
            .then(res => {
                successnotify();
                getLoan();
            })
            .catch(err => {
                errornotify();
                console.log(err);
            });
    };


    return (
        <div>
            {/* modal start  */}

            {/* Modal Show Start  */}
            <Modal size="lg" show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> {targetRecord.amDesc} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={serverIP + targetRecord.fileUrl} style={{ width: '100%', height: '100%' }} />
                </Modal.Body>
            </Modal>
            {/* Modal Show End  */}

            {/* Modal Change Guarantee Start */}
            <Modal size="lg" show={gpModal} onHide={() => setGpModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> ዋስ ግለሰብ ቀይር </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <select
                        className="form-select"
                        onChange={e => {
                            setGpid(e.target.value);
                        }}
                    >
                        <option value="null" selected>
                            ተበዳሪ
                        </option>
                        {targetLoan.gp?.length > 0 &&
                            targetLoan.gp.map(gp => {
                                return (
                                    <option key={gp.id} value={gp.id}>
                                        {gp.fullname}
                                    </option>
                                );
                            })}
                    </select>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning btn-sm" onClick={() => changeGP()}>
                        Change
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Modal Change Guarantee Start  */}

            {/* add modal Start  */}
            <Modal size='lg' show={showAddModal} onHide={() => {
                setShowAddModal(false)
                setEditingRecord(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title> አዲስ የቤት መያዣ </Modal.Title>
                </Modal.Header>

                <Formik
                    initialValues={editingRecord || initialValues}
                    validationSchema={HouseSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({ values, errors, touched, handleChange, setFieldValue }) => (
                        <Form>
                            <Modal.Body className='flex w-full'>
                                <Card className='w-1/2 p-1'>
                                    <InputField
                                        label="የካርታ መ.ቁ."
                                        name="uniquenum"
                                        placeholder="የካርታ መ.ቁ."
                                        touched={touched.uniquenum}
                                        errors={errors.uniquenum}
                                    />

                                    <InputField
                                        label="ህንጻው ያረፈበት ስፋት"
                                        name="homearea"
                                        placeholder="ህንጻው ያረፈበት ስፋት"
                                        touched={touched.homearea}
                                        errors={errors.homearea}
                                    />

                                    <InputField
                                        label="ጠቅላላ ስፋት"
                                        name="totalhomearea"
                                        placeholder="ጠቅላላ ስፋት"
                                        touched={touched.totalhomearea}
                                        errors={errors.totalhomearea}
                                    />

                                    <InputField
                                        label="ሴሪ ቁጥር"
                                        name="serialnum"
                                        placeholder="ሴሪ ቁጥር"
                                        touched={touched.serialnum}
                                        errors={errors.serialnum}
                                    />
                                    <InputField
                                        label="የእግድ አድራሻ"
                                        name="sanctionAddress"
                                        placeholder="የእግድ አድራሻ"
                                        touched={touched.sanctionAddress}
                                        errors={errors.sanctionAddress}
                                    />
                                    <InputField
                                        label="የቤት ቁጥር"
                                        name="houseno"
                                        placeholder="የቤት ቁጥር"
                                        touched={touched.houseno}
                                        errors={errors.houseno}
                                    />
                                    <InputField
                                        label="ቤቱ በተመለከተ ገለጻ"
                                        name="amDescription"
                                        placeholder="ቤቱ በተመለከተ ገለጻ"
                                        touched={touched.amDescription}
                                        errors={errors.amDescription}
                                    />
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        ክልል
                                    </label>
                                    <Field
                                        as="select"
                                        name="regionId"
                                        className={baseStyle}
                                    >
                                        <option value="">ክልል ይምረጡ </option>
                                        {regions.map(reg => (
                                            <option key={reg.id} value={reg.id}>
                                                {reg.amDescription}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage style={{ color: 'red' }} name="regionId" component="div" />


                                    <div>
                                        <Field type="checkbox" name="isFenceIsolation" />  IS THE FENCE OF TH BIULDING AND THE BIULDING IT SELF ISOLATED FROM THE NEIBOURHOOD FENCE AND BIULDING
                                    </div>
                                </Card>


                                <Card className='w-1/2 p-1'>
                                    <div className="mb-1">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Building Material
                                        </label>
                                        <Field
                                            as="select"
                                            name="buildingmaterial"
                                            className={baseStyle}
                                        >
                                            <option value="">Select Building Material</option>
                                            {buildingmaterials.map(bm => (
                                                <option key={bm.id} value={bm.id}>
                                                    {bm.amName}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage style={{ color: 'red' }} name="buildingmaterial" component="div" />

                                    </div>
                                    <div className="mb-1">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Building Type
                                        </label>
                                        <Field
                                            as="select"
                                            name="buildingtype"
                                            className={baseStyle}
                                        >
                                            <option value="">Select Building Type</option>
                                            {buildingtypes.map(bt => (
                                                <option key={bt.id} value={bt.id}>
                                                    {bt.amName}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage style={{ color: 'red' }} name="buildingtype" component="div" />

                                    </div>
                                    <div className="mb-1">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Floor
                                        </label>
                                        <Field
                                            as="select"
                                            name="floorlocation"
                                            className={baseStyle}
                                        >
                                            <option value="">Select Floor</option>
                                            {floorlocations.map(floor => (
                                                <option key={floor.id} value={floor.id}>
                                                    {floor.amName}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage style={{ color: 'red' }} name="floorlocation" component="div" />

                                    </div>

                                    <div className="mb-1">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Distance From Asphalt
                                        </label>
                                        <Field
                                            as="select"
                                            name="fromasphalt"
                                            className={baseStyle}
                                        >
                                            <option value="">Distance From Asphalt</option>
                                            {fromasphalts.map(fas => (
                                                <option key={fas.id} value={fas.id}>
                                                    {fas.amName}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage style={{ color: 'red' }} name="fromasphalt" component="div" />

                                    </div>
                                    <div className="mb-1">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Location
                                        </label>
                                        <Field
                                            as="select"
                                            name="location"
                                            className={baseStyle}
                                        >
                                            <option value="">Select Location</option>
                                            {locations.map(locs => (
                                                <option key={locs.id} value={locs.id}>
                                                    {locs.amName}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage style={{ color: 'red' }} name="location" component="div" />

                                    </div>
                                    <div className="mb-1">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Neighbour
                                        </label>
                                        <Field
                                            as="select"
                                            name="neighbour"
                                            className={baseStyle}
                                        >
                                            <option value="">Select Neighbour</option>
                                            {neighbours.map(nebors => (
                                                <option key={nebors.id} value={nebors.id}>
                                                    {nebors.amName}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage style={{ color: 'red' }} name="neighbour" component="div" />

                                    </div>
                                    <div className="mb-1">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Physical Appearance
                                        </label>
                                        <Field
                                            as="select"
                                            name="physicalappearance"
                                            className={baseStyle}
                                        >
                                            <option value="">Select Physical Appearance</option>
                                            {physicalappearances.map(pha => (
                                                <option key={pha.id} value={pha.id}>
                                                    {pha.amName}
                                                </option>
                                            ))}
                                        </Field>

                                        <ErrorMessage style={{ color: 'red' }} name="physicalappearance" component="div" />
                                    </div>



                                    <div className="mb-1">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Set-Back
                                        </label>
                                        <Field
                                            as="select"
                                            name="setback"
                                            className={baseStyle}
                                        >
                                            <option value="">Select Set-Back</option>
                                            {setbacks.map(sbks => (
                                                <option key={sbks.id} value={sbks.id}>
                                                    {sbks.amName}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage style={{ color: 'red' }} name="setback" component="div" />

                                    </div>


                                    <div className="mb-1">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Home Use
                                        </label>
                                        <Field
                                            as="select"
                                            name="homeuse"
                                            className={baseStyle}
                                        >
                                            <option value="">Select Home Use</option>
                                            {homeuses.map(huse => (
                                                <option key={huse.id} value={huse.id}>
                                                    {huse.amName}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage style={{ color: 'red' }} name="homeuse" component="div" />

                                    </div>

                                    <div className="mb-1">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Road To Site
                                        </label>
                                        <Field
                                            as="select"
                                            name="accessroadtosite"
                                            className={baseStyle}
                                        >
                                            <option value="">Select Road To Site</option>
                                            {roadtosite.map(rtsite => (
                                                <option key={rtsite.id} value={rtsite.id}>
                                                    {rtsite.amName}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage style={{ color: 'red' }} name="accessroadtosite" component="div" />
                                    </div>
                                </Card>
                            </Modal.Body>
                            <Modal.Footer className='flex justify-end'>
                                <Button type="submit" variant={editingRecord ? "warning" : "primary"} >
                                    {editingRecord ? 'Update' : 'Save'}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>

            </Modal>
            {/* add modal end  */}

            {/* delete modal Start  */}
            <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> በመያዣነት የቀረበው ቤት አጥፋ </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div>{targetRecord?.buildingtype?.enName}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger btn-sm" onClick={() => deleteRecord()}>
                        አጥፋ
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* delete modal End  */}

            {/* modal end  */}

            <Alert className="px-3 flex flex-row justify-between py-1 items-center">
                <div style={{ fontSize: 20, fontWeight: 'bold', color: 'GrayText' }}>የቤት መያዣ</div>
                <div>
                    {data?.groups[0] === 'ROLE_OFFICER' && (
                        <Button
                            onClick={() => setShowAddModal(true)}
                            className="flex flex-row items-center gap-2"
                            disabled={targetLoan?.loanstatus?.id !== 1}
                        >
                            <FaHome /> ጨምር
                        </Button>
                    )}
                </div>
            </Alert>

            {/* accordion home  */}

            <div className="row">
                <div className="col-sm-12" style={{ paddingTop: 5 }}>
                    {Object.keys(targetLoan)?.length > 0 ? (
                        <Accordion defaultActiveKey="1">
                            {targetLoan.collateralhome.map(home => {
                                return (
                                    <Accordion.Item eventKey={home.id}>
                                        <Accordion.Header>
                                            <div className="flex flex-row items-center gap-2">
                                                <FaFileAlt />{' '}
                                                <Badge>
                                                    {home.homefiles.filter(x => x.isUploaded === true)?.length}/ {home.homefiles?.length}
                                                </Badge>
                                                {`${home?.buildingtype?.amName} - ስፋት ${home.homearea} ካሬ, በካሬ ${home?.buildingtype?.percentAllowed.toLocaleString()} ብር ግምት => `}
                                                <FaDollarSign /> {singleHomePrice(home)?.toLocaleString()}

                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>


                                            <div className="flex flex-row p-2 justify-between bg-slate-500 mb-1 text-white">
                                                <span>{home.homegp !== null ? home.homegp : 'የተበዳሪ'}</span>
                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                    <Button
                                                        onClick={() => {
                                                            setEditingRecord({
                                                                buildingmaterial: home.buildingmaterial?.id,
                                                                buildingtype: home.buildingtype?.id,
                                                                floorlocation: home.floorlocation?.id,

                                                                fromasphalt: home.fromasphalt?.id,
                                                                location: home.location?.id,
                                                                neighbour: home.neighbour?.id,

                                                                physicalappearance: home.physicalappearance?.id,
                                                                setback: home.setback?.id,
                                                                homeuse: home.homeuse?.id,
                                                                id: home.id,

                                                                uniquenum: home.uniquenum,
                                                                homearea: home.homearea,
                                                                totalhomearea: home.totalhomearea,

                                                                serialnum: home.serialnum,
                                                                sanctionAddress: home.sanctionAddress,
                                                                houseno: home.houseno,

                                                                amDescription: home.amDescription,
                                                                isFenceIsolation: home.isFenceIsolation,
                                                                accessroadtosite: home.accessroadtosite?.id,
                                                                regionId: home.regionId,
                                                            });
                                                            setShowAddModal(true);
                                                            setTargetRecord(home)
                                                        }}
                                                        className="btn btn-sm btn-warning"
                                                        disabled={targetLoan?.loanstatus?.id !== 1}
                                                    >
                                                        አስተካክል
                                                    </Button>
                                                    <button
                                                        onClick={() => {
                                                            setGpModal(true);
                                                            setTargetRecord(home);

                                                        }}
                                                        type="button"
                                                        className="btn btn-sm btn-primary"
                                                        disabled={targetLoan?.loanstatus?.id !== 1}
                                                    >
                                                        ተያዥ ቀይር
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            setModalDelete(true);
                                                            setTargetRecord(home);
                                                        }}
                                                        className="btn btn-sm btn-danger"
                                                        disabled={targetLoan?.loanstatus?.id !== 1}
                                                    >
                                                        አጥፋ
                                                    </button>
                                                </div>
                                            </div>

                                            {home.homefiles?.length > 0 ? (
                                                <>
                                                    <Alert
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between'
                                                        }}
                                                        className="mt-2 py-2 items-center"
                                                    >
                                                        <div>በቤት ዋስትና የሚሰጥ የብድር ሰነድ ማረጋገጫ ዝርዝር</div>
                                                        <Button
                                                            className="btn btn-warning btn-sm"
                                                            disabled={targetLoan?.loanstatus?.id !== 1}
                                                            onClick={() => {
                                                                reLoadData(home.id);
                                                            }}
                                                        >
                                                            የፋይል ዝርዝር አጥፋ
                                                        </Button>
                                                    </Alert>

                                                    <Table striped bordered hover style={{ margin: 1 }}>
                                                        <thead>
                                                            <tr>
                                                                <th> የፋይል ስም </th>
                                                                <th> እይ </th>
                                                                <th> ድርጊት </th>
                                                                <th> አጥፋ </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {home.homefiles.map(upload => {
                                                                return (
                                                                    <tr key={upload.id} className={upload.isUploaded ? `` : `table-warning`}>
                                                                        <td>{upload.amDesc}</td>
                                                                        <td>
                                                                            {upload.isUploaded ? (
                                                                                <>
                                                                                    {upload.fileType === 'application/pdf' ? (
                                                                                        <a
                                                                                            target="_blank"
                                                                                            href={serverIP + upload.fileUrl}
                                                                                            rel="noopener noreferrer"
                                                                                        >
                                                                                            <FaFileAlt />
                                                                                        </a>
                                                                                    ) : (
                                                                                        <FaEye
                                                                                            onClick={() => {
                                                                                                setTargetRecord(upload);
                                                                                                setShow(true);
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </td>

                                                                        <td style={{ width: '30%', padding: 0 }}>
                                                                            {data.groups[0] === 'ROLE_OFFICER' ? (
                                                                                <>
                                                                                    <InputGroup style={{ paddingTop: 5 }}>
                                                                                        <input
                                                                                            onChange={e => {
                                                                                                setFile(e.target.files[0]);
                                                                                            }}
                                                                                            type="file"
                                                                                            className="form-control"
                                                                                        />
                                                                                        <Button disabled={targetLoan?.loanstatus?.id !== 1} variant="outline-primary" onClick={() => handleUpload(upload)}>
                                                                                            {!upload.isUploaded ? <> ጫን </> : <>አዘምን</>}
                                                                                        </Button>
                                                                                    </InputGroup>
                                                                                </>
                                                                            ) : (
                                                                                <>{upload.isUploaded ? 'ተሰቅሏል' : ' '}</>
                                                                            )}
                                                                        </td>
                                                                        <td style={{ width: '10%', padding: 0 }}>
                                                                            {upload.isUploaded && (
                                                                                <Button disabled={targetLoan?.loanstatus?.id !== 1} variant="outline-danger" onClick={() => handleDeleteFile(upload)}>
                                                                                    አጥፋ
                                                                                </Button>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </>
                                            ) : (
                                                <Alert className="flex justify-between p-2">
                                                    <div> በቤት ዋስትና የሚሰጥ የብድር ሰነድ ማረጋገጫ ዝርዝር </div>
                                                    <Button disabled={targetLoan?.loanstatus?.id !== 1} className="btn-sm" onClick={() => loadData(home.id)}>
                                                        ሳብ
                                                    </Button>
                                                </Alert>
                                            )}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                );
                            })}
                        </Accordion>
                    ) : (
                        <></>
                    )}
                </div>
            </div>

            {/* accordion end  */}

        </div>
    )
}

export default House