import { Font, StyleSheet } from '@react-pdf/renderer';
import ebrimanormal from '../font/ebrima.ttf';
import ebrimabold from '../font/ebrimabd.ttf';
import AmharicFont from "../font/ebrima.ttf";


Font.register({
    family: 'ebrimanormal',
    src: ebrimanormal
});

Font.register({
    family: 'ebrimabold',
    src: ebrimabold
});


Font.register({
    family: 'AmharicFont',
    src: AmharicFont
});

const ReportStyle = StyleSheet.create({
    body: {
        flexDirection: 'column',
        padding: 40,
        fontSize: 12,
        height: '100%'
    },

    // Header 
    container: {
        marginTop: -15,
        display: "flex",
        flexDirection: "row",
        borderBottomWidth: 2,
        borderBottomColor: "#FF7700",
        borderBottomStyle: "solid",
        justifyContent: "space-between",
        paddingBottom: 5,
    },
    ReporIimage: {
        height: 35,
        width: "30%",
    },
    name: {
        fontSize: 12,
        fontFamily: "AmharicFont",
    },
    normalText: {
        fontSize: 9,
        fontFamily: "AmharicFont",
    },

    // Agreement doc 
    heading_one: {
        marginTop: 5,
        marginBottom: 2,
        fontSize: 12,
        textAlign: 'center',
        fontFamily: 'ebrimabold',
        fontWeight: 'bold'
    },
    heading_two: {
        marginTop: 6,
        marginBottom: 3,
        fontSize: 9,
        fontFamily: 'ebrimanormal',
        fontWeight: 'bold'
    },
    text_normal: {
        fontSize: 10,
        fontFamily: 'ebrimanormal',
        marginTop: 3
    },
    textpaddingleft30: {
        marginTop: 1,
        fontSize: 9,
        fontFamily: 'AmharicFont',
        fontWeight: 'bold',
        paddingLeft: 30
    },

    // footer 
    footer: {
        position: 'absolute',
        bottom: 30,
        right: 30,
        fontSize: 10,
        fontFamily: 'AmharicFont',
        color: 'grey'
    },

    h5: {
        fontFamily: 'AmharicFont',
        fontSize: 8
    },
    h5b: {
        fontFamily: 'AmharicFont',
        fontSize: 8
    },

});

export default ReportStyle;
