import React, { useEffect, useRef, useState } from 'react'
import { Button, Image } from 'react-bootstrap';
import { FaPrint } from 'react-icons/fa';
import AmigosLogo from "../Images/new.PNG";
import NewFormInput from '../Role_Officer/NewFormInput';
import { useDispatch, useSelector } from 'react-redux';
import { calculate_principal, Calculate_totalInterest, calculateMonthlyPay, Expected_collateralValues, flat_service_and_life_insurance, life_insurance, Savings_for_loan_deduction } from '../Common/modules/requestForm';
import axiosInstance from '../axiosInstance';
import { setTargetLoan } from '../Common/redux/systemLookups';

function RequestForm({ successnotify, errornotify, targetLoan, targetClient }) {
    const curdate = new Date()
    const { serverIP } = useSelector(store => store.allsettings);
    const printRef = useRef();
    const dispatch = useDispatch();


    // manipulated 
    const [daysToLoanProcess, setDaysToLoanProcess] = useState(0)
    const [approvedPrincipal, setApprovedPrincipal] = useState(0)
    const [age, setAge] = useState(0)
    const [serviceCharge, setServiceCharge] = useState(0)
    const [annualInterestRate, setAnnualInterestRate] = useState(0)
    const [numberOfRepayments, setNumberOfRepayments] = useState(0)

    const [amount_compulsary, setAmount_compulsary] = useState(0)
    const [amount_loansaving, setAmount_loansaving] = useState(0)
    const [amount_share, setAmount_share] = useState(0)
    const [savingsPrcnt, setSavingsPrcnt] = useState(0)
    const [regularSavingAdded, setRegularSavingAdded] = useState(false)

    const [toLoanSavingAddedAmount, setToLoanSavingAddedAmount] = useState(0)
    const [formattedDate, setFormattedDate] = useState("")


    const [ddlbranches, setddlbranches] = useState([])
    const [ddlloangoal, setddlloangoal] = useState([])
    const [ddlloantype, setddlloantype] = useState([])
    const [ddlsavingdecipline, setddlsavingdecipline] = useState([])
    const [ddleducationlevel, setddleducationlevel] = useState([])

    // cbo 
    const [requestbranch, setrequestbranch] = useState("")


    function getFormattedDate(daysToAdd) {
        const currentDate = new Date(targetLoan.submittedOnDate);
        currentDate.setDate(currentDate.getDate() + daysToAdd);

        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return currentDate.toLocaleDateString('en-US', options);
    }

    useEffect(() => {
        getLoan();
        getAllLookups();
    }, [])


    const getAllLookups = async () => {
        const branch_res = await axiosInstance.get(`${serverIP}/requestbranch/requestbranches`)
        setddlbranches(branch_res.data);

        const goal_res = await axiosInstance.get(`${serverIP}/requestloanpurpose/requestloanpurpose`)
        setddlloangoal(goal_res.data);

        const loantype_res = await axiosInstance.get(`${serverIP}/loantype/loantypes`)
        setddlloantype(loantype_res.data);

        const saving_deci = await axiosInstance.get(`${serverIP}/ddlsavingdecipline/savingdecipline`)
        setddlsavingdecipline(saving_deci.data);

        const edu_level = await axiosInstance.get(`${serverIP}/requestlevelofeducation/requestlevelofeducation`)
        setddleducationlevel(edu_level.data);
    }

    const getLoan = () => {
        axiosInstance
            .get(`${serverIP}/loan/loans/${targetLoan.id}`)
            .then(res => {
                setAge(res.data.age);
                setAmount_compulsary(res.data.amount_compulsary);
                setAmount_loansaving(res.data.amount_loansaving);
                setAmount_share(res.data.amount_share);
                setApprovedPrincipal(res.data.approvedPrincipal);
                setNumberOfRepayments(res.data.numberOfRepayments);
                setAnnualInterestRate(res.data.annualInterestRate);
                setServiceCharge(res.data.prcntServiceCharge);
                setDaysToLoanProcess(res.data.daysToLoanProcess);
                setFormattedDate(getFormattedDate(res.data.daysToLoanProcess));
                setRegularSavingAdded(res.data.isAddedToLoanSaving);
                setToLoanSavingAddedAmount(res.data.toLoanSavingAddedAmount);
                setrequestbranch(res.data?.requestbranch?.id);

                dispatch(setTargetLoan({ targetLoan: res.data }));
            })
            .catch(err => {
                console.log(err);
            });
    };

    const saveLoan = () => {
        axiosInstance
            .patch(`${serverIP}/loan/loans/${targetLoan.id}`, {
                newSavingPrcnt: savingsPrcnt,
                newTotalDueForPeriod: calculateMonthlyPay(false, targetLoan),
                age: age,
                amount_compulsary: amount_compulsary,
                amount_loansaving: amount_loansaving,
                amount_share: amount_share,
                approvedPrincipal: approvedPrincipal,
                daysToLoanProcess: daysToLoanProcess,
                isAddedToLoanSaving: regularSavingAdded,
                toLoanSavingAddedAmount: toLoanSavingAddedAmount,
                prcntServiceCharge: serviceCharge,
                requestbranch: requestbranch,
                numberOfRepayments: numberOfRepayments,
                annualInterestRate: annualInterestRate
            })
            .then(res => {
                successnotify('የብድር መረጃ ተስተካክሏል!');
                getLoan();
                console.log(res.data)
            })
            .catch(err => {
                console.log(err);
                errornotify("የብድር መረጃ አልተስተካከለም");
            });
    };

    const handlePrint = () => {
        const printContents = printRef.current.innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    };

    return (
        <div className="p-2 bg-gray-100 min-h-screen" >

            <div className='flex justify-between mb-2'>
                <Button variant="secondary" onClick={saveLoan}> SAVE </Button>
                <button onClick={handlePrint} className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400" >
                    <FaPrint className="mr-2" /> Print
                </button>
            </div>

            <div className="bg-white shadow-md rounded-lg overflow-hidden" ref={printRef}>
                {/* Header */}
                <div className="flex w-full p-2">
                    <Image className='h-24 w-3/12' src={AmigosLogo} />
                    <div className='flex flex-col w-8/12 justify-center items-center'>
                        <div className='text-xl font-bold text-orange-500'>
                            አሚጎስ የገንዘብ ቁጠባና ብድር ተቋም
                        </div>
                        <div className='text-xl font-bold text-orange-500'>
                            የብድር መጠየቅያ ቅጽ
                        </div>
                    </div>
                    <Image className='h-24 w-3/12' src={AmigosLogo} />
                </div>

                {/* Data Section */}
                <div className="flex w-full gap-2 p-2">
                    <div className='w-2/3'>
                        <NewFormInput
                            value={targetClient.amDisplayName}
                            disabled={true}
                            label="ሙሉስም" />
                        <NewFormInput
                            value={targetClient.mobileNo}
                            disabled={true}
                            label="አድራሻ /ስልክ " />
                        <NewFormInput
                            value={targetClient.memberSince}
                            disabled={true}
                            label="አባል የሆኑበት ቀን (DD/MM/YY)" />
                        <NewFormInput
                            placeh="ጥያቄው የቀረበበት ቀን (DD/MM/YY)"
                            disabled={true}
                            value={curdate.toDateString()}
                            label="ጥያቄው የቀረበበት ቀን (DD/MM/YY)" />
                        <NewFormInput
                            label="መለያ ቁጥር"
                            disabled={true}
                            value={targetClient.entityExternalId} />
                        <NewFormInput
                            label={`ተበዳሪ ዕድሜ`}
                            value={age}
                            onChange={e => setAge(e.target.value)}
                        />
                        <NewFormInput value={amount_compulsary} onChange={e => setAmount_compulsary(e.target.value)} label="ያላቸው የመደበኛ ቁጠባ መጠን " />
                        <NewFormInput value={amount_loansaving} onChange={e => setAmount_loansaving(e.target.value)} label="ያላቸው የብድር ቁጠባ መጠን" />
                        <NewFormInput value={amount_share} onChange={e => setAmount_share(e.target.value)} label="ያላቸው የአክስዮን መጠን" />

                        <div className='flex w-full mb-1'>
                            <label className="flex w-1/2 justify-end items-center mr-2">የብድር ጥያቄው የቁጠባ ስሌት በ%</label>
                            <select className="form-control w-1/2 border border-blue-500 bg-green-100" onChange={e => setSavingsPrcnt(e.target.value)}>
                                <option value="15" selected={targetLoan.newSavingPrcnt == 15}> 15 </option>
                                <option value="20" selected={targetLoan.newSavingPrcnt == 20}> 20 </option>
                                <option value="25" selected={targetLoan.newSavingPrcnt == 25}> 25 </option>
                                <option value="30" selected={targetLoan.newSavingPrcnt == 30}> 30 </option>
                                <option value="35" selected={targetLoan.newSavingPrcnt == 35}> 35 </option>
                                <option value="40" selected={targetLoan.newSavingPrcnt == 40}> 40 </option>
                                <option value="45" selected={targetLoan.newSavingPrcnt == 45}> 45 </option>
                                <option value="50" selected={targetLoan.newSavingPrcnt == 50}> 50 </option>
                            </select>
                        </div>

                        <NewFormInput
                            value={approvedPrincipal}
                            onChange={e => setApprovedPrincipal(e.target.value)}
                            label="የብድር መጠን" />

                        <NewFormInput
                            value={numberOfRepayments}
                            onChange={e => setNumberOfRepayments(e.target.value)}
                            label="የብድር መመለሻ ግዜ (ወር)" />

                        <NewFormInput
                            value={annualInterestRate}
                            onChange={e => setAnnualInterestRate(e.target.value)}
                            label="የብድር ወለድ በ%" />

                        <NewFormInput
                            value={serviceCharge}
                            onChange={e => setServiceCharge(e.target.value)}
                            label="የአግልግሎት ክፍያ በ%" />

                        <NewFormInput
                            label="ብድር ፕሮሰስ የሚጀምሩበት ቀን"
                            value={daysToLoanProcess}
                            onChange={e => setDaysToLoanProcess(e.target.value)} />

                        <div className="form-check flex justify-end">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={regularSavingAdded}
                                onChange={e => {
                                    setRegularSavingAdded(e.target.checked);
                                }}
                            />
                            <label className="pl-4 form-check-label">በመ.ቁጠባ ብድር ይጨመራል</label>
                        </div>

                        {regularSavingAdded &&
                            <NewFormInput
                                onChange={e => setToLoanSavingAddedAmount(e.target.value)}
                                value={toLoanSavingAddedAmount}
                                label="የተጨመረው መጠን" />}

                        <NewFormInput
                            disabled={true}
                            value={life_insurance(targetLoan)}
                            label="የሂወት መድህን ክፍያ %" />

                        <NewFormInput
                            disabled={true}
                            value={flat_service_and_life_insurance(targetLoan)?.toLocaleString()}
                            label="የአግልግሎት እና ኢንሹራንስ ክፍያ" />

                        <NewFormInput
                            disabled={true}
                            value={Savings_for_loan_deduction(targetLoan)?.toLocaleString()}
                            label="ለብድር መቀነሻ የሚሆን ቁጠባ" />

                        <NewFormInput
                            disabled={true}
                            value={calculate_principal(targetLoan)?.toLocaleString()}
                            label="ዋናው ብድር(principal)" />
                    </div>
                    <div className='w-1/3 p-2 bg-slate-400 rounded-md'>
                        <div>
                            <label className="form-label">ቅርንጫፍ </label>
                            <select
                                as="select"
                                className="form-control"
                                value={requestbranch}
                                onChange={e => {
                                    setrequestbranch(e.target.value);
                                }}>
                                <option value="0"> ቅርንጫፍ ይምረጡ </option>
                                {ddlbranches.length > 0 &&
                                    ddlbranches.map(branch => {
                                        return (
                                            <option selected={branch.id == targetLoan.requestbranch?.id} key={branch.id} value={branch.id}>
                                                {branch.amName}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                        <div>
                            <label className="form-label">የብድር አላማ </label>
                            <select className="form-control" >
                                <option value="0"> የብድር አላማ ይምረጡ </option>
                                {ddlloangoal?.length > 0 &&
                                    ddlloangoal.map(goal => {
                                        return (
                                            <option selected={goal.id == 2} key={goal.id} value={goal.id}>
                                                {goal.amName}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                        <div>
                            <label className="form-label">የብድር አይነት</label>
                            <select className="form-control" >
                                <option value="0"> የብድር አይነት ይምረጡ </option>
                                {ddlloantype?.length > 0 &&
                                    ddlloantype.map(type => {
                                        return (
                                            <option selected={type.id == targetLoan?.loanType?.id} key={type.id} value={type.id}>
                                                {type.amName}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                        <div>
                            <label className="form-label">የአባልነት ደረጃ </label>
                            <select className="form-control" >
                                <option value="0"> የአባልነት ደረጃ ይምረጡ </option>

                                {ddlsavingdecipline?.length > 0 &&
                                    ddlsavingdecipline.map(decipline => {
                                        return (
                                            <option selected={decipline.id == targetLoan.savingdecipline_id} key={decipline.id} value={decipline.id}>
                                                {decipline.amName}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                        <div >
                            <label className="form-label">ጾታ</label>
                            <input disabled={true} className="form-control" value={targetClient.gender} />
                        </div>
                        <div>
                            <label className="form-label"> የጋብቻ ሁኔታ</label>
                            <input disabled={true} className="form-control" value={targetClient.isMarried ? "ያገባ " : "ያላገባ "} />
                        </div>
                        <div>
                            <label className="form-label">የትምህርት ደረጃ</label>
                            <select className="form-control" >
                                <option value="0"> የትምህርት ደረጃ ይምረጡ</option>
                                {ddleducationlevel?.length > 0 &&
                                    ddleducationlevel.map(edu => {
                                        return (
                                            <option selected={edu.id == targetLoan?.educationLevel?.id} key={edu.id} value={edu.id}>
                                                {edu.amName}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                        <div className='flex flex-col bg-slate-100 mt-4 items-center justify-center rounded-lg gap-4 py-4'>
                            <div> የብድር ሂደት የሚጀመርበት ቀን </div>
                            <div className='text-xl font-bold'> {formattedDate} </div>
                        </div>
                    </div>
                </div>

                <div className='px-4'>
                    <NewFormInput label="ተበዳሪ እጅላይ የሚደርስ ብር" value={(targetLoan.approvedPrincipal + targetLoan.toLoanSavingAddedAmount)?.toLocaleString()} disabled={true} />
                    <NewFormInput label="ወርሃዊ ክፍያ" value={calculateMonthlyPay(targetLoan.isAddedToLoanSaving, targetLoan)?.toLocaleString()} disabled={true} />
                    <NewFormInput label="ጠቅላላ የወለድ መጠን" value={Calculate_totalInterest(targetLoan)?.toLocaleString()} disabled={true} />
                    <NewFormInput label="መቅረብ ያለበት የዋስትና መጠን" disabled={true} value={Expected_collateralValues(targetLoan)?.toLocaleString()} />
                </div>

                {/* Footer */}
                <div className="bg-gray-50 p-4 text-center text-sm">
                    ተበዳሪ አስፈላጊ የብድር ሰነዶችን ማሟላት የሚጀምረው የወረፋ ጊዜ ከተጠናቀቀ በኋላ ይሆናል፡፡ ማንኛውም ተበዳሪ የንብረት የግዥ ውል የሚዋዋለው
                    በአሚጎስ ከተገመተ በኋላ ይሆናል፡፡ የብድር አስፈላጊ ሰነዶች ካሟሉ በኋላ ለብድር ውል ዝግጅት /LOAN PROCESS/ ይደረጋል፡፡ ከብድር ውል የመጨረሻ
                    ፊርማ በኋላ 15 የስራ ቀናት ለብድር ኮሚቴ ፊርማ እንዲሁም ለቼክ ዝግጅት /LOAN DISBURSEMENT/ ተበዳሪው ካላቸው የቁጠባ ተቀማጭ ባልተከፈለ (ዋናው)
                    ብድር በየአመቱ 2% የብድር ኢንሹራንስ ለመክፈል ይገደዳሉ፡፡ ይህም የብድር መመለሻ አመቱ ሳይደርስ ብድር የሚመልስ ከሆነ የብድር ኢንሹራንስ የማይቆረጥበት ይሆናል፡፡
                    አመታዊ ኢንሹራንስ ክፍያው 0.5% ከአባሉ አጠቃላይ ተቀማጭ ሂሳብ (አክሲዎን ፣ መደበኛ ቁጠባ ፣ ፍቃደኝነት ቁጠባ) በአመት አንዴ አንድ አባል ብድር ጠይቆ የብድር
                    ቀጠሮ ቀኑ ደርሶ የብድር ሂደት ሳይጀምር 6 ወር ካለፈው ብድሩ ይቋረጣል፡፡
                </div>
            </div>
        </div>
    )
}

export default RequestForm