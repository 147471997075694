function calculate_principal(targetLoan) {
    if (!targetLoan) return 0;
    let d12 = targetLoan.approvedPrincipal;
    let d21 = Savings_for_loan_deduction(targetLoan);

    let diff = d12 - d21;
    let res = targetLoan?.isAddedToLoanSaving ? diff + targetLoan.toLoanSavingAddedAmount : diff;

    return Number(res.toFixed(2));
}

function calculateAge(dateOfBirth) {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
    }
    return 35;
}


function getValueBasedOnAge(age) {
    if (age <= 45) {
        return 2;
    } else if (age > 45 && age <= 60) {
        return 4;
    } else if (age > 60) {
        return 10;
    }
}

function life_insurance(targetLoan) {
    if (!targetLoan) return 0;
    const agefromdob = targetLoan.age
    return getValueBasedOnAge(Number(agefromdob));
}


function life_insurance_flat(targetLoan) {
    if (!targetLoan) return 0;
    let ins_prcnt = life_insurance(targetLoan);

    const res = ins_prcnt * targetLoan.approvedPrincipal * 0.01;
    return Number(res.toFixed(2));
}

function service_charge_flat(targetLoan) {
    if (!targetLoan) return 0;
    const res = targetLoan.prcntServiceCharge * targetLoan.approvedPrincipal * 0.01;
    return Number(res.toFixed(2));
}


function flat_service_and_life_insurance(targetLoan) {
    const res = ((targetLoan.prcntServiceCharge + life_insurance(targetLoan)) * (targetLoan.approvedPrincipal + targetLoan.toLoanSavingAddedAmount)) / 100
    return Number(res.toFixed(2));
}


function calculateMonthlyPay(E9, targetLoan) {
    const monthlyInterestRate = targetLoan.annualInterestRate / 1200;
    const numberOfPayments = targetLoan.numberOfRepayments

    const monthlyPayment =
        (calculate_principal(targetLoan) * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
        (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);

    return Number(monthlyPayment.toFixed(2))
}


function Expected_collateralValues(targetLoan) {
    const monthlypay = calculateMonthlyPay(targetLoan.toLoanSavingAddedAmount, targetLoan)
    const allPay = monthlypay * targetLoan.numberOfRepayments

    return Number(allPay.toFixed(2));
}

// ለብድር መቀነሻ የሚሆን ቁጠባ
function Savings_for_loan_deduction(targetLoan) {
    let res = targetLoan.amount_loansaving - (targetLoan.approvedPrincipal * 5 / 100) - ((targetLoan.prcntServiceCharge + targetLoan.prcntLifeInsurance) * (targetLoan.approvedPrincipal + targetLoan.toLoanSavingAddedAmount)) / 100
    return Number(res.toFixed(2))
}

function Calculate_totalInterest(targetLoan) {
    const monthlyPayment = calculateMonthlyPay(targetLoan.toLoanSavingAddedAmount, targetLoan)

    // Calculate total payment and total interest
    const totalPayment = monthlyPayment * targetLoan?.numberOfRepayments;
    const totalInterest = totalPayment - calculate_principal(targetLoan);

    return Number(totalInterest.toFixed(2));
}

function Calculate_totalCollateral(targetLoan) {
    const numberOfPayments = targetLoan?.numberOfRepayments; // Total number of monthly payments

    // Calculate the monthly payment using the formula for an annuity
    const monthlyPayment = calculateMonthlyPay(false, targetLoan)

    // Calculate total payment and total interest
    const totalPayment = monthlyPayment * numberOfPayments;
    const totalInterest = totalPayment - targetLoan.approvedPrincipal;

    return Number(totalInterest.toFixed(2));
}

module.exports = {
    calculate_principal,
    life_insurance,
    calculateAge,
    flat_service_and_life_insurance,
    calculateMonthlyPay,
    Savings_for_loan_deduction,
    Calculate_totalInterest,
    Calculate_totalCollateral,
    life_insurance_flat,
    service_charge_flat,
    Expected_collateralValues
};

