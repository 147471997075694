import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import AgreementStyles from '../Styles/ReportStyle';
import ReportStyle from '../Styles/ReportStyle';
import HeaderHistory from './HeaderHistory';
import { useSelector } from 'react-redux';
import Tr from '../../Contracts/Reusables/Tr';

import Trc from '../../Contracts/Reusables/Trc';

import axiosInstance from '../../axiosInstance';

function RptPlansLoan() {
  const { serverIP } = useSelector(store => store.allsettings);

  const [loansofficer, setLoansofficer] = useState([]);
  const [loansteamleader, setLoansteamleader] = useState([]);
  const [loansauditor, setLoansauditor] = useState([]);

  const [loanofficersum, setLoanofficersum] = useState(0);
  const [loanteamleadersum, setLoanteamleadersum] = useState(0);
  const [loanauditorsum, setLoanauditorsum] = useState(0);

  useEffect(() => {
    getLoanOnOfficer();
    getLoanOnTeamLeader();
    getLoanOnAuditor();
  }, []);

  const getLoanOnOfficer = () => {
    axiosInstance
      .get(`${serverIP}/loan/loanonofficer`)
      .then(res => {
        console.log(res.data);
        setLoansofficer(res.data);
        const sum_lns = res.data?.reduce((accum, obj) => {
          return accum + obj.approvedPrincipal;
        }, 0);

        setLoanofficersum(sum_lns);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getLoanOnTeamLeader = () => {
    axiosInstance
      .get(`${serverIP}/loan/loanonteamleader`)
      .then(res => {
        console.log(res.data);
        setLoansteamleader(res.data);
        const sum_lns = res.data?.reduce((accum, obj) => {
          return accum + obj.approvedPrincipal;
        }, 0);

        setLoanteamleadersum(sum_lns);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getLoanOnAuditor = () => {
    axiosInstance
      .get(`${serverIP}/loan/loanonauditor`)
      .then(res => {
        console.log(res.data);
        setLoansauditor(res.data);
        const sum_lns = res.data?.reduce((accum, obj) => {
          return accum + obj.approvedPrincipal;
        }, 0);

        setLoanauditorsum(sum_lns);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <PDFViewer style={{ width: '100%', height: 1200 }}>
      <Document title="ያላለቁ ብድሮች ሪፖርት">
        <Page style={AgreementStyles.body} wrap>
          <HeaderHistory type="ያላለቁ ብድሮች ሪፖርት" />
          {/* loan on officer Start  */}
          <Trc borders="t0r0b0l0" thekey="በኦፊሰሮች ስር ያሉ" />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: 'gray',
              marginTop: 5
            }}
          >
            <Tr style={ReportStyle.h5b} thekey="ሙሉ ስም" width="30%" borders="t1r1b1l1" />
            <Tr style={ReportStyle.h5b} thekey="የብድር መጠን" width="19%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey="የተጠየቀበት ቀን  - የቀጠሮ ቀን" width="25%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey="ቀሪ ቀናቶች" width="8%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey=" ስልክ " width="18%" borders="t1r1b1l0" />
          </View>
          {loansofficer.length > 0 &&
            loansofficer.map(loan => {
              return (
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Tr style={ReportStyle.h5b} thekey={loan.customer.amDisplayName} width="30%" borders="t0r1b1l1" />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan?.approvedPrincipal?.toLocaleString()}
                    width="19%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan.submittedOnDate + ' - ' + loan.auditorat}
                    width="25%"
                    borders="t0r1b1l0"
                  />
                  <Tr style={ReportStyle.h5b} thekey={0 + 1} width="8%" borders="t0r1b1l0" />
                  <Tr style={ReportStyle.h5b} thekey={loan.customer.mobileNo} width="18%" borders="t0r1b1l0" />
                </View>
              );
            })}

          <View style={{ paddingTop: 10, paddingLeft: 5 }}>
            <Text style={ReportStyle.h5b}>ጠቅላላ የብድር መጠን: {loanofficersum?.toLocaleString()}</Text>
            <Text style={ReportStyle.h5b}>የብድር ብዛት : {loansofficer.length} </Text>
          </View>
          {/* loan on officer End  */}

          {/* loan on Team Leader Start  */}
          <Trc borders="t0r0b0l0" thekey="በቡድን መሪ ስር ያሉ" />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: 'gray',
              marginTop: 5
            }}
          >
            <Tr style={ReportStyle.h5b} thekey="ሙሉ ስም" width="30%" borders="t1r1b1l1" />
            <Tr style={ReportStyle.h5b} thekey="የብድር መጠን" width="19%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey="የተጠየቀበት ቀን  - የቀጠሮ ቀን" width="25%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey="ቀሪ ቀናቶች" width="8%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey=" ስልክ " width="18%" borders="t1r1b1l0" />
          </View>
          {loansteamleader.length > 0 &&
            loansteamleader.map(loan => {
              return (
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Tr style={ReportStyle.h5b} thekey={loan.customer.amDisplayName} width="30%" borders="t0r1b1l1" />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan?.approvedPrincipal?.toLocaleString()}
                    width="19%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan.submittedOnDate + ' - ' + loan.auditorat}
                    width="25%"
                    borders="t0r1b1l0"
                  />
                  <Tr style={ReportStyle.h5b} thekey={0 + 1} width="8%" borders="t0r1b1l0" />
                  <Tr style={ReportStyle.h5b} thekey={loan.customer.mobileNo} width="18%" borders="t0r1b1l0" />
                </View>
              );
            })}

          <View style={{ paddingTop: 10, paddingLeft: 5 }}>
            <Text style={ReportStyle.h5b}>ጠቅላላ የብድር መጠን: {loanteamleadersum?.toLocaleString()}</Text>
            <Text style={ReportStyle.h5b}>የብድር ብዛት : {loansteamleader.length} </Text>
          </View>
          {/* loan on Team Leader End  */}

          {/* loan on Auditor Start  */}
          <Trc borders="t0r0b0l0" thekey="በኦዲተር ስር ያሉ" />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: 'gray',
              marginTop: 5
            }}
          >
            <Tr style={ReportStyle.h5b} thekey="ሙሉ ስም" width="30%" borders="t1r1b1l1" />
            <Tr style={ReportStyle.h5b} thekey="የብድር መጠን" width="19%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey="የተጠየቀበት ቀን  - የቀጠሮ ቀን" width="25%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey="ቀሪ ቀናቶች" width="8%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey=" ስልክ " width="18%" borders="t1r1b1l0" />
          </View>
          {loansauditor.length > 0 &&
            loansauditor.map(loan => {
              return (
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Tr style={ReportStyle.h5b} thekey={loan.customer.amDisplayName} width="30%" borders="t0r1b1l1" />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan?.approvedPrincipal?.toLocaleString()}
                    width="19%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan.submittedOnDate + ' - ' + loan.auditorat}
                    width="25%"
                    borders="t0r1b1l0"
                  />
                  <Tr style={ReportStyle.h5b} thekey={0 + 1} width="8%" borders="t0r1b1l0" />
                  <Tr style={ReportStyle.h5b} thekey={loan.customer.mobileNo} width="18%" borders="t0r1b1l0" />
                </View>
              );
            })}

          <View style={{ paddingTop: 10, paddingLeft: 5 }}>
            <Text style={ReportStyle.h5b}>ጠቅላላ የብድር መጠን: {loanauditorsum?.toLocaleString()}</Text>
            <Text style={ReportStyle.h5b}>የብድር ብዛት : {loansauditor.length} </Text>
          </View>
          {/* loan on Auditor End  */}
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default RptPlansLoan;
