import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import AgreementStyles from '../Styles/ReportStyle';
import ReportStyle from '../Styles/ReportStyle';
import HeaderHistory from './HeaderHistory';
import { useSelector } from 'react-redux';
import Tr from '../../Contracts/Reusables/Tr';

import Trc from '../../Contracts/Reusables/Trc';

import axiosInstance from '../../axiosInstance';

function RptHistoryWithdraw() {
  const { serverIP } = useSelector(store => store.allsettings);

  const [withdrawplanner, setwithdrawplanner] = useState([]);
  const [withdrawevaluator, setwithdrawevaluator] = useState([]);
  const [withdrawauditor, setwithdrawauditor] = useState([]);
  const [withdrawfinance, setwithdrawfinance] = useState([]);

  const [withdrawplannersum, setwithdrawplannersum] = useState(0);
  const [withdrawpevaluatorsum, setwithdrawpevaluatorsum] = useState(0);
  const [withdrawauditorsum, setwithdrawauditorsum] = useState(0);
  const [withdrawfinancesum, setwithdrawfinancesum] = useState(0);

  const curdate = new Date();

  useEffect(() => {
    getwithdrawplanner();
    getwithdrawevaluator();
    getwithdrawauditor();
    getwithdrawfinance();
  }, []);

  const getwithdrawplanner = () => {
    axiosInstance
      .get(`${serverIP}/withdrawrows/withdrawrow`)
      .then(res => {
        console.log(res.data);
        setwithdrawplanner(res.data);
        const sum_bisburses = res.data?.reduce((accum, obj) => {
          return accum + obj.withdraw_amount;
        }, 0);

        setwithdrawplannersum(sum_bisburses);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getwithdrawevaluator = () => {
    axiosInstance
      .get(`${serverIP}/withdrawrows/atmanager`)
      .then(res => {
        console.log(res.data);
        setwithdrawevaluator(res.data);
        const sum_bisburses = res.data?.reduce((accum, obj) => {
          return accum + obj.withdraw_amount;
        }, 0);

        setwithdrawpevaluatorsum(sum_bisburses);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getwithdrawauditor = () => {
    axiosInstance
      .get(`${serverIP}/withdrawrows/atevaluator`)
      .then(res => {
        console.log(res.data);
        setwithdrawauditor(res.data);
        const sum_bisburses = res.data?.reduce((accum, obj) => {
          return accum + obj.withdraw_amount;
        }, 0);

        setwithdrawauditorsum(sum_bisburses);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getwithdrawfinance = () => {
    axiosInstance
      .get(`${serverIP}/withdrawrows/atfinance`)
      .then(res => {
        console.log(res.data);
        setwithdrawfinance(res.data);
        const sum_bisburses = res.data?.reduce((accum, obj) => {
          return accum + obj.withdraw_amount;
        }, 0);

        setwithdrawfinancesum(sum_bisburses);
      })
      .catch(err => {
        console.log(err);
      });
  };

  function getDateDifferenceInDays(plannedDate, appointmentDate) {
    // loan.plannedDate + " - " + loan.appointmentDate
    const Conv_plannedDate = new Date(plannedDate);
    const Conv_appointmentDate = new Date(appointmentDate);
    const differenceInTime = Conv_appointmentDate - Conv_plannedDate;
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
    return differenceInDays;
  }

  return (
    <PDFViewer style={{ width: '100%', height: 1200 }}>
      <Document title="ያለቁ ብድሮች ሪፖርት">
        <Page style={AgreementStyles.body} wrap>
          <HeaderHistory type="ያለቁ ብድሮች ሪፖርት" />
          {/* planner start  */}
          <Trc borders="t0r0b0l0" thekey="planner ሪፖርት" />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: 'gray',
              marginTop: 5
            }}
          >
            <Tr style={ReportStyle.h5b} thekey="ሙሉ ስም" width="22%" borders="t1r1b1l1" />
            <Tr style={ReportStyle.h5b} thekey="የብር መጠን" width="19%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey="የተጠየቀበት ቀን  - የቀጠሮ ቀን" width="25%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey="የቀረው ቀናት" width="12%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey=" ስልክ " width="22%" borders="t1r1b1l0" />
          </View>
          {withdrawplanner.length > 0 &&
            withdrawplanner.map(loan => {
              return (
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Tr style={ReportStyle.h5b} thekey={loan?.parent?.amDisplayName} width="22%" borders="t0r1b1l1" />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan?.withdraw_amount?.toLocaleString()}
                    width="19%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan.plannedDate + ' - ' + loan.appointmentDate}
                    width="25%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={getDateDifferenceInDays(loan.plannedDate, loan.appointmentDate)}
                    width="12%"
                    borders="t0r1b1l0"
                  />
                  <Tr style={ReportStyle.h5b} thekey={loan?.parent?.mobileNo} width="22%" borders="t0r1b1l0" />
                </View>
              );
            })}

          <View style={{ paddingTop: 10, paddingLeft: 5 }}>
            <Text style={ReportStyle.h5b}>ጠቅላላ የብር መጠን: {withdrawplannersum?.toLocaleString()}</Text>
            <Text style={ReportStyle.h5b}>የብድር ብዛት : {withdrawplanner.length} </Text>
          </View>
          {/* planner End  */}

          {/* Evaluator start  */}
          <Trc borders="t0r0b0l0" thekey="Evaluator ሪፖርት" />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: 'gray',
              marginTop: 5
            }}
          >
            <Tr style={ReportStyle.h5b} thekey="ሙሉ ስም" width="22%" borders="t1r1b1l1" />
            <Tr style={ReportStyle.h5b} thekey="የብር መጠን" width="19%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey="የተጠየቀበት ቀን  - የቀጠሮ ቀን" width="25%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey="የቀረው ቀናት" width="12%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey=" ስልክ " width="22%" borders="t1r1b1l0" />
          </View>
          {withdrawevaluator.length > 0 &&
            withdrawevaluator.map(loan => {
              return (
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Tr style={ReportStyle.h5b} thekey={loan?.parent?.amDisplayName} width="22%" borders="t0r1b1l1" />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan?.withdraw_amount?.toLocaleString()}
                    width="19%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan.plannedDate + ' - ' + loan.appointmentDate}
                    width="25%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={getDateDifferenceInDays(loan.plannedDate, loan.appointmentDate)}
                    width="12%"
                    borders="t0r1b1l0"
                  />
                  <Tr style={ReportStyle.h5b} thekey={loan?.parent?.mobileNo} width="22%" borders="t0r1b1l0" />
                </View>
              );
            })}

          <View style={{ paddingTop: 10, paddingLeft: 5 }}>
            <Text style={ReportStyle.h5b}>ጠቅላላ የብር መጠን: {withdrawpevaluatorsum?.toLocaleString()}</Text>
            <Text style={ReportStyle.h5b}>የብድር ብዛት : {withdrawevaluator.length}</Text>
          </View>
          {/* Evaluator End  */}

          {/* Auditor start  */}
          <Trc borders="t0r0b0l0" thekey="Auditor ሪፖርት" />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: 'gray',
              marginTop: 5
            }}
          >
            <Tr style={ReportStyle.h5b} thekey="ሙሉ ስም" width="22%" borders="t1r1b1l1" />
            <Tr style={ReportStyle.h5b} thekey="የብር መጠን" width="19%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey="የተጠየቀበት ቀን  - የቀጠሮ ቀን" width="25%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey="የቀረው ቀናት" width="12%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey=" ስልክ " width="22%" borders="t1r1b1l0" />
          </View>
          {withdrawauditor.length > 0 &&
            withdrawauditor.map(loan => {
              return (
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Tr style={ReportStyle.h5b} thekey={loan?.parent?.amDisplayName} width="22%" borders="t0r1b1l1" />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan?.withdraw_amount?.toLocaleString()}
                    width="19%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan.plannedDate + ' - ' + loan.appointmentDate}
                    width="25%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={getDateDifferenceInDays(loan.plannedDate, loan.appointmentDate)}
                    width="12%"
                    borders="t0r1b1l0"
                  />
                  <Tr style={ReportStyle.h5b} thekey={loan?.parent?.mobileNo} width="22%" borders="t0r1b1l0" />
                </View>
              );
            })}

          <View style={{ paddingTop: 10, paddingLeft: 5 }}>
            <Text style={ReportStyle.h5b}>ጠቅላላ የብር መጠን: {withdrawauditorsum?.toLocaleString()}</Text>
            <Text style={ReportStyle.h5b}>የብድር ብዛት : {withdrawauditor.length} </Text>
          </View>
          {/* Auditor End  */}

          {/* Finance start  */}
          <Trc borders="t0r0b0l0" thekey="Finance ሪፖርት" />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: 'gray',
              marginTop: 5
            }}
          >
            <Tr style={ReportStyle.h5b} thekey="ሙሉ ስም" width="22%" borders="t1r1b1l1" />
            <Tr style={ReportStyle.h5b} thekey="የብር መጠን" width="19%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey="የተጠየቀበት ቀን  - የቀጠሮ ቀን" width="25%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey="የቀረው ቀናት" width="12%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey=" ስልክ " width="22%" borders="t1r1b1l0" />
          </View>
          {withdrawfinance.length > 0 &&
            withdrawfinance.map(loan => {
              return (
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Tr style={ReportStyle.h5b} thekey={loan?.parent?.amDisplayName} width="22%" borders="t0r1b1l1" />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan?.withdraw_amount?.toLocaleString()}
                    width="19%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan.plannedDate + ' - ' + loan.appointmentDate}
                    width="25%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={getDateDifferenceInDays(loan.plannedDate, loan.appointmentDate)}
                    width="12%"
                    borders="t0r1b1l0"
                  />
                  <Tr style={ReportStyle.h5b} thekey={loan?.parent?.mobileNo} width="22%" borders="t0r1b1l0" />
                </View>
              );
            })}

          <View style={{ paddingTop: 10, paddingLeft: 5 }}>
            <Text style={ReportStyle.h5b}>ጠቅላላ የብር መጠን: {withdrawfinancesum?.toLocaleString()}</Text>
            <Text style={ReportStyle.h5b}>የብድር ብዛት : {withdrawfinance.length} </Text>
          </View>
          {/* Finance End  */}
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default RptHistoryWithdraw;
