import React from 'react';
import { Document, Page } from '@react-pdf/renderer';
import { Text, View, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';

import GuaranteeStyles from '../ReportStyle';
import AmigosLogo from '../../Images/Head.PNG';

import { numberToWords } from '../../Common/modules/EnNumToWord';
import TextBoldUnderlined from '../../Contracts/Reusables/TextBoldUnderlined';
import CarDateAndAddress from '../Reusables/CarDateAndAddress';
import { Expected_collateralValues } from '../../Common/modules/requestForm';

function AmCarProhibition({ loan, car }) {

  return (
    <PDFViewer style={{ width: '100%', height: 700 }}>
      <Document title="የመኪና እግድ">
        <Page style={GuaranteeStyles.prohibitionbody} wrap>
          <Image style={GuaranteeStyles.CarProhibition_ReporIimage} src={AmigosLogo} />
          <CarDateAndAddress objec={car} />

          <View style={{ paddingTop: 10 }}>
            <Text style={GuaranteeStyles.subjectCenter}>ጉዳዩ ፡- እገዳ እንዲደረግልን ስለመጠየቅ ይመለከታል፡፡</Text>
          </View>

          <View style={{ paddingTop: 10 }}>
            <Text style={GuaranteeStyles.h1}>
              የድርጅታችን አባል የሆኑት {loan.customer?.gender === 'MALE' ? ' አቶ ' : ' ወ/ሮ(ወ/ሪት) '}
              <TextBoldUnderlined text={loan.customer.amDisplayName} />
              ከማህበራችን ብር
              <Text style={GuaranteeStyles.h1b}>
                {` ${Expected_collateralValues(loan)?.toLocaleString()} ( ${numberToWords(Expected_collateralValues(loan))} ) `}
              </Text>
              በብድር የወሰዱ ሲሆን ለብድሩ መያዣነት ያቀረቡትን ማለትም፡፡
            </Text>
          </View>

          {/* Table Start */}

          <View style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <View style={{ width: '10%' }}></View>
            <View style={{ width: '80%' }}>
              <View
                style={{
                  width: '100%',
                  fontSize: 10,
                  paddingTop: 15,
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <View style={{ width: '40%' }}>
                  <Text
                    style={{
                      ...GuaranteeStyles.border_rbl,
                      backgroundColor: 'gray'
                    }}
                  >
                    የዋስትናው አይነት
                  </Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text
                    style={{
                      ...GuaranteeStyles.border_rbl,
                      backgroundColor: 'gray'
                    }}
                  >
                    መኪና
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  fontSize: 10,
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <View style={{ width: '40%' }}>
                  <Text style={GuaranteeStyles.border_rbl}>የዋስትና ሰጪ ስም </Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text style={GuaranteeStyles.border_rb}>
                    {car.cargp === null ? loan.customer.amDisplayName : car.cargp?.fullname}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  fontSize: 10,
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <View style={{ width: '40%' }}>
                  <Text
                    style={{
                      ...GuaranteeStyles.border_rbl,
                      backgroundColor: 'gray'
                    }}
                  >
                    የሻንሲ ቁጥር
                  </Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text
                    style={{
                      ...GuaranteeStyles.border_rbl,
                      backgroundColor: 'gray'
                    }}
                  >
                    {car.chassisNumber}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  fontSize: 10,
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <View style={{ width: '40%' }}>
                  <Text style={GuaranteeStyles.border_rbl}>ሞተር ቁጥር </Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text style={GuaranteeStyles.border_rb}>{car.engineNumber}</Text>
                </View>
              </View>
              {/* <View
              style={{
                width: '100%',
                fontSize: 10,
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <View style={{ width: '40%' }}>
                <Text
                  style={{
                    ...GuaranteeStyles.border_rbl,
                    backgroundColor: 'gray'
                  }}
                >
                  የመኪና ግምት 
                </Text>
              </View>
              <View style={{ width: '60%' }}>
                <Text
                  style={{
                    ...GuaranteeStyles.border_rbl,
                    backgroundColor: 'gray'
                  }}
                >
                  {singleCarPrice(car)?.toLocaleString()}
                </Text>
              </View>
            </View> */}

              {/**/} <View
                style={{
                  width: '100%',
                  fontSize: 10,
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <View style={{ width: '40%' }}>
                  <Text
                    style={{
                      ...GuaranteeStyles.border_rbl,
                      backgroundColor: 'gray'
                    }}
                  >
                    ሴሪ ቁጥር
                  </Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text
                    style={{
                      ...GuaranteeStyles.border_rbl,
                      backgroundColor: 'gray'
                    }}
                  >
                    {` ${car.serialnum}`}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: '100%',
                  fontSize: 10,
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <View style={{ width: '40%' }}>
                  <Text style={GuaranteeStyles.border_rbl}> ታርጋ ቁጥር </Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text style={GuaranteeStyles.border_rb}>{car.carPlate}</Text>
                </View>
              </View>
            </View>
            <View style={{ width: '10%' }}></View>
          </View>

          {/* Table End */}
          <View style={{ paddingTop: 10 }}>
            <Text style={GuaranteeStyles.h1}>
              እንዲታገድ ፣ እንዳይሸጥ እንዳይለወጥ እንዲሁመ ለሌላ ሶስተኛ ወገን እንዳይተላለፍ በአሚጎስ የገ/ቁ/በ/ህ/ስ/ማ ስም ተመዝገቦ እንዲቆይ እየጠየቅን ፣ የዋስትናው
              ደኩመንት በድርጅታችን ሰራተኛ በኩል እንድትልኩልን የዘወትር የስራ ትብብራችሁን እንጠይቃለን፡፡
            </Text>
          </View>
          {/* ከሰላምታ ጋር  */}
          <View style={{ paddingTop: 10 }}>
            <Text style={GuaranteeStyles.subjectRight}>ከሰላምታ ጋር </Text>
          </View>
          <View style={{ marginTop: 180, rowGap: 5 }}>
            <View
              style={{
                width: '100%',
                fontSize: 10,
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <View style={{ width: '50%' }}>
                <Text style={GuaranteeStyles.h1}>አዘጋጅ ፡ ____________________________</Text>
              </View>
              <View style={{ width: '50%' }}>
                <Text style={GuaranteeStyles.h1}>ያረጋገጠው ፡ _________________________</Text>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                fontSize: 10,
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <View style={{ width: '50%' }}>
                <Text style={GuaranteeStyles.h1}>የስራ ድርሻ ፡ _________________________</Text>
              </View>
              <View style={{ width: '50%' }}>
                <Text style={GuaranteeStyles.h1}>የስራ ድርሻ ፡ _________________________</Text>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                fontSize: 10,
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <View style={{ width: '50%' }}>
                <Text style={GuaranteeStyles.h1}>ፊርማ ፡ _________________________</Text>
              </View>
              <View style={{ width: '50%' }}>
                <Text style={GuaranteeStyles.h1}>ፊርማ ፡ _________________________</Text>
              </View>
            </View>
            <View style={{ marginTop: 60 }}></View>

            <Image style={GuaranteeStyles.CarProhibition_ReporIimage} src={AmigosLogo} />
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default AmCarProhibition