import React from 'react';
import { Document, Font, Page, Text, View, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import ReportStyle from '../ReportStyle';
import AmigosLogo from "../../Images/new.PNG";
import TblAmCover from "../Reusables/TblAmCover"
import {
    calculate_principal,
    Calculate_totalCollateral,
    Calculate_totalInterest,
    calculateAge,
    flat_service_and_life_insurance,
    life_insurance,
    life_insurance_flat,
    Savings_for_loan_deduction,
    service_charge_flat
} from '../../Common/modules/requestForm';
import Tr from '../Reusables/Tr';
import { numberToWordOnly, numberToWords, numberToWordsPercent } from '../../Common/modules/EnNumToWord';
import TextBold from '../Reusables/TextBold';

import ebrimanormal from '../../font/ebrima.ttf';

Font.register({
    family: 'ebrimanormal',
    src: ebrimanormal
});

function AmCover({ customer, loan, committee }) {
    const curdate = new Date()
    return (
        <PDFViewer style={{ width: '100%', height: 700 }}>
            <Document title="ብድር መጠየቅያ ፎርም">
                <Page style={ReportStyle.body} wrap>
                    {/* <GuaranteeHeader type="የቤት" /> */}
                    <View style={ReportStyle.container}>
                        <Image style={ReportStyle.ReporIimage} src={AmigosLogo} />
                        <View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                <Text style={ReportStyle.name}> አሚጎስ የገንዘብ ቁጠባና ብድር ተቋም  </Text>
                                <Text style={ReportStyle.name}> የብድር መጠየቅያ ቅጽ </Text>
                            </View>
                        </View>
                        <Image style={ReportStyle.ReporIimage} src={AmigosLogo} />
                    </View>
                    {/* <View style={{ marginTop: 5}}></View> */}


                    <View style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignContent: "flex-end", marginTop: 10 }}>
                        <Text> Date : {curdate.toDateString()} </Text>
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: 10 }}>
                        <View style={{ width: "60%" }}>
                            <View style={{ borderBottomWidth: 1, }} />
                            <TblAmCover zkey="የአመልካች ስም ከነአያት" zvalue={customer.amDisplayName} />
                            <TblAmCover zkey="ክ/ከተማ " zvalue={customer.zoneId} />
                            <TblAmCover zkey="ወረዳ " zvalue={customer.woredaId} />
                            <TblAmCover zkey="የቤት ቁጥር " zvalue={loan.houseNum} />
                            <TblAmCover zkey="ስልክ ቁጥር" zvalue={customer.mobileNo} />

                            <TblAmCover zkey="የመታወቅያ ቁጥር" zvalue={customer.idNum} />
                            <TblAmCover zkey="የጋብቻ ሁኔታ" zvalue={customer.isMarried ? "ያገባ " : "ያላገባ "} />
                            <TblAmCover zkey="የማህበሩ አባል የሆኑበት ጊዜ" zvalue={customer.memberSince} />
                        </View>
                        <View style={{ width: "40%", padding: 3, rowGap: 8 }}>
                            <Text style={ReportStyle.normalText}>
                                የተበዳሪ መለያ ቁጥር: {customer.entityAccountNo + " / " + customer.entityExternalId}
                            </Text>
                            <Text style={ReportStyle.normalText}>
                                የብድር አይነት: {loan.loanType?.amName}
                            </Text>
                            <Text style={ReportStyle.normalText}>
                                ውል ሰጪ: _______________________________
                            </Text>
                            <Text style={ReportStyle.normalText}>
                                የብድር ክፍል ሃላፊ: _______________________________
                            </Text>
                        </View>
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <Text style={ReportStyle.normalText}>
                            እኔ ስሜ አመልካች ተብሎ የተጠቀሰው ለመበደር ጠይቄ ከድርጅቱ ከተፈቀደልኝ ብድር ማለትም
                            {(loan.approvedPrincipal + loan.toLoanSavingAddedAmount)?.toLocaleString()}
                            <TextBold text={` (${numberToWords(loan.approvedPrincipal + loan.toLoanSavingAddedAmount)}) `} /> ሲሆን በድርጅቱ ካለኝ የብድር ቁጠባ ማለትም
                            {Savings_for_loan_deduction(loan)?.toLocaleString()}
                            <TextBold text={` (${numberToWords(Savings_for_loan_deduction(loan))}) `} />
                            ወጪ ተደርጎ እንዲሰጠኝ ፣ በቀሪው ብር ማለትም
                            {calculate_principal(loan)?.toLocaleString()}
                            <TextBold text={` (${numberToWords(calculate_principal(loan))}) `} />
                            ብድር ሆኖ በ {loan?.numberOfRepayments}
                            <TextBold text={` (${numberToWordOnly(loan?.numberOfRepayments)}) `} />
                            ወራት የሚመለስ ተደርጎ እንዲሰራልኝ እየጠየቅኩኝ
                            የአዲሱ ብድር ወርሃዊ ክፍያ {loan?.newTotalDueForPeriod?.toLocaleString()}
                            <TextBold text={` (${numberToWords(loan?.newTotalDueForPeriod)}) `} />
                            ለመክፈል የተስማማሁኝ መሆኔን በፊርማዮ አረጋግጣለው።
                        </Text>
                    </View>

                    <View style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: 10 }}>
                        <View style={{ width: "60%" }}>
                            <View style={{ borderBottomWidth: 1, }} />
                            <TblAmCover zkey="ከተፈቀደው ብድር ካለኝ የብድር ቁጠባ ተቀንሶ ቀሪ ብድር" zvalue={(calculate_principal(loan))?.toLocaleString()} />
                            <TblAmCover zkey="ብድሩ ተከፍሎ የሚያልቅበት ወር" zvalue={loan?.numberOfRepayments} />
                            <TblAmCover zkey="አመታዊ የወለድ መጠን " zvalue={loan?.annualInterestRate} />
                            <TblAmCover zkey="አጠቃላይ የብድር መጠን ወለድን ጨምሮ" zvalue={(Calculate_totalCollateral(loan) + loan?.approvedPrincipal)?.toLocaleString()} />
                            <TblAmCover zkey="ወርሀዊ የወለድና የዋናው ብድር ድምር ክፍያ" zvalue={loan?.newTotalDueForPeriod?.toLocaleString()} />
                            <TblAmCover zkey="ወርሀዊ መደበኛ ቁጠባ" zvalue="1,000" />
                            <TblAmCover zkey="በቅድሚያ የሚከፈል የአገልግሎት ክፍያ " zvalue={service_charge_flat(loan)?.toLocaleString()} />
                            <TblAmCover zkey="የኢንሹራንስ ክፍያ " zvalue={life_insurance_flat(loan)?.toLocaleString()} />
                            <TblAmCover zkey="ድምር የአገልግሎት የቴምብር እና የኢንሹራንስ ክፍያ" zvalue={(flat_service_and_life_insurance(loan) + 5)?.toLocaleString()} />
                            <TblAmCover zkey="ክፍያዎች ተቀንሰው በተበዳሪ እጅ የሚደርሰው ገንዘብ" zvalue={(calculate_principal(loan))?.toLocaleString()} />
                        </View>
                        <View style={{ width: "40%", padding: 3, rowGap: 8 }}>
                            <Text style={ReportStyle.normalText}>
                                ተበዳሪው፤ የሚጠበቅባቸውን በአግባቡ ስላሙዋሉ  ብድሩ እንዲፈቀድላቸው ስል በፊርማዬ አረጋግጣለሁ ፡፡
                            </Text>
                            <Text style={{
                                color: 'black',
                                textDecoration: 'underline',
                                fontWeight: 'bold',
                                fontSize: 10,
                                fontFamily: 'ebrimanormal',
                                marginTop: 3
                            }}>
                                ስም: {loan.submittedBy?.first_name + " " + loan.submittedBy?.last_name}
                            </Text>
                            <Text style={ReportStyle.normalText}>
                                ፊርማ: _______________________________
                            </Text>
                            <Text style={ReportStyle.normalText}>
                                ውል ሰጪ ፡የብድር ክፍል ሃላፊ
                            </Text>
                            <Text style={ReportStyle.normalText}>
                                ስም: _______________________________
                            </Text>
                            <Text style={ReportStyle.normalText}>
                                ፊርማ: _______________________________
                            </Text>
                        </View>
                    </View>

                    <View style={{ display: "flex", flexDirection: "row", paddingTop: 5 }}>
                        <View style={{ width: "50%", rowGap: 5 }}>
                            <Text style={ReportStyle.normalText}>
                                አመልካች / ተበዳሪ
                            </Text>
                            <Text style={{
                                color: 'black',
                                textDecoration: 'underline',
                                fontWeight: 'bold',
                                fontSize: 10,
                                fontFamily: 'ebrimanormal',
                                marginTop: 3
                            }}>
                                ስም: {customer.amDisplayName}
                            </Text>
                            <Text style={ReportStyle.normalText}>
                                ፊርማ: _______________________________
                            </Text>
                        </View>
                        {customer.isMarried && <View style={{ width: "50%", rowGap: 5 }}>
                            <Text style={ReportStyle.normalText}>
                                የተበዳሪ ባለቤት
                            </Text>
                            <Text style={{
                                color: 'black',
                                textDecoration: 'underline',
                                fontWeight: 'bold',
                                fontSize: 10,
                                fontFamily: 'ebrimanormal',
                                marginTop: 3
                            }}>
                                ስም: _______________________________
                            </Text>
                            <Text style={ReportStyle.normalText}>
                                ፊርማ: _______________________________
                            </Text>
                        </View>}
                    </View>

                    <View style={{ display: 'flex', textAlign: 'center', padding: 2, backgroundColor: "gray", marginTop: 10 }}>
                        <Text style={ReportStyle.normalText}> የብድር ኮሚቴ ቃለ ጉባኤ እና ውሳኔ  </Text>
                    </View>

                    {/* የብድር ኮሚቴዎች Start */}
                    <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: "gray", }} >
                        <Tr style={ReportStyle.h5b} thekey="የብድር ኮሚቴ" width="50%" borders="t1r1b1l1" />
                        <Tr style={ReportStyle.h5b} thekey="የስራ ድርሻ" width="30%" borders="t1r1b1l0" />
                        <Tr style={ReportStyle.h5b} thekey="ፊርማ" width="20%" borders="t1r1b1l0" />
                    </View>

                    {committee?.length > 0 &&
                        committee.map(cmt => {
                            return (
                                <View style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Tr thekey={cmt.amFullName} width="50%" borders="t0r1b1l1" />
                                    <Tr thekey={cmt.jobposition?.amName} width="30%" borders="t0r1b1l0" />
                                    <Tr thekey=" " width="20%" borders="t0r1b1l0" />
                                </View>
                            );
                        })}
                    {/* የብድር ኮሚቴዎች End */}

                    <View style={{ display: 'flex', textAlign: 'center', flexDirection: "row", marginTop: 10 }}>
                        <Text style={ReportStyle.normalText}> የውስጥ ኦዲተር (ስም): ____________________________________________________    </Text>
                        <Text style={ReportStyle.normalText}> ፊርማ: _____________________   </Text>
                    </View>

                    <View style={{ display: 'flex', textAlign: 'center', padding: 2, backgroundColor: "gray", marginTop: 10 }}>
                        <Text style={ReportStyle.normalText}> እማኞች   </Text>
                    </View>

                    <View style={{ borderBottomWidth: 1, }} />
                    <TblAmCover zkey=" " zvalue=" " />
                    <TblAmCover zkey=" " zvalue=" " />
                    <TblAmCover zkey=" " zvalue=" " />

                    <View style={{ marginTop: 10 }}>
                        <Text style={ReportStyle.normalText}>
                            ተበዳሪ አስፈላጊ የብድር ሰነዶችን ማሟላት የሚጀምረው የወረፋ ጊዜ ከተጠናቀቀ በኋላ ይሆናል፡፡ ማንኛውም ተበዳሪ የንብረት የግዥ ውል የሚዋዋለው በአሚጎስ ከተገመተ በኋላ ይሆናል፡፡
                            የብድር አስፈላጊ ሰነዶች ካሟሉ በኋላ ለብድር ውል ዝግጅት /LOAN PROCESS/ ይደረጋል፡፡
                            ከብድር ውል የመጨረሻ ፊርማ በኋላ 15 የስራ ቀናት ለብድር ኮሚቴ ፊርማ እንዲሁም ለቼክ ዝግጅት /LOAN DISBURSEMENT/ ተበዳሪው ካላቸው የቁጠባ ተቀማጭ ባልተከፈለ (ዋናው)
                            ብድር በየአመቱ 2% የብድር ኢንሹራንስ ለመክፈል ይገደዳሉ፡፡ ይህም የብድር መመለሻ አመቱ ሳይደርስ ብድር የሚመልስ ከሆነ የብድር ኢንሹራንስ የማይቆረጥበት ይሆናል፡፡ አመታዊ ኢንሹራንስ ክፍያው 0.5%
                            ከአባሉ አጠቃላይ ተቀማጭ ሂሳብ (አክሲዎን ፣ መደበኛ ቁጠባ ፣ ፍቃደኝነት ቁጠባ) በአመት አንዴ አንድ አባል ብድር ጠይቆ የብድር ቀጠሮ ቀኑ ደርሶ የብድር ሂደት ሳይጀምር 6 ወር ካለፈው ብድሩ ይቋረጣል፡፡
                        </Text>
                    </View>

                </Page>
            </Document>
        </PDFViewer>
    )
}

export default AmCover