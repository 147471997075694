import React from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import ReportStyle from '../ReportStyle';
import AmigosLogo from "../../Images/new.PNG";
import TblLoanRequest from "../Reusables/TblLoanRequest"
import {
  calculate_principal,
  Calculate_totalCollateral,
  Calculate_totalInterest,
  calculateAge,
  flat_service_and_life_insurance,
  life_insurance,
  Savings_for_loan_deduction
} from '../../Common/modules/requestForm';
import TextBoldUnderlined from '../Reusables/TextBoldUnderlined';
import TextBold from '../Reusables/TextBold';

function AmLoanRequest({ customer, loan }) {
  return (
    <PDFViewer style={{ width: '100%', height: 700 }}>
      <Document title="ብድር መጠየቅያ ፎርም">
        <Page style={ReportStyle.body} wrap>
          {/* <GuaranteeHeader type="የቤት" /> */}
          <View style={ReportStyle.container}>
            <Image style={ReportStyle.ReporIimage} src={AmigosLogo} />
            <View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Text style={ReportStyle.name}> አሚጎስ የገንዘብ ቁጠባና ብድር ተቋም  </Text>
                <Text style={ReportStyle.name}> የብድር መጠየቅያ ቅጽ </Text>
              </View>
            </View>
            <Image style={ReportStyle.ReporIimage} src={AmigosLogo} />
          </View>
          {/* <View style={{ marginTop: 5}}></View> */}
          <View style={{
            display: "flex",
            flexDirection: "row",
            width: "100%"
          }}>
            <View style={{ width: "70%" }}>
              <TblLoanRequest zkey="ሙሉ ስም" zvalue={customer.amDisplayName} />
              <TblLoanRequest zkey="አድራሻ/ስልክ/" zvalue={customer.mobileNo} />
              <TblLoanRequest zkey="አባል የሆኑበት ቀን (DD/MM/YY)" zvalue={customer.memberSince} />
              <TblLoanRequest zkey="ጥያቄው የቀረበበት ቀን (DD/MM/YY)" zvalue={loan.submittedOnDate} />
              <TblLoanRequest zkey="መለያ ቁጥር" zvalue={customer.entityExternalId} />
              <TblLoanRequest zkey={`የተበዳሪ ዕድሜ `} zvalue={loan.age} />
              <TblLoanRequest zkey="ያላቸው የመደበኛ ቁጠባ መጠን" zvalue={loan?.amount_compulsary?.toLocaleString()} />
              <TblLoanRequest zkey="ያላቸው የብድር ቁጠባ መጠን" zvalue={loan?.amount_loansaving?.toLocaleString()} />
              <TblLoanRequest zkey="ያላቸው የአክስዮን መጠን" zvalue={(loan?.r_amount_share)?.toLocaleString()} />
              <TblLoanRequest zkey="የብድር ጥያቄው የቁጠባ ስሌት በ%" zvalue={loan?.newSavingPrcnt} />
              <TblLoanRequest zkey="የብድር መጠን" zvalue={loan?.approvedPrincipal?.toLocaleString()} />

              <TblLoanRequest zkey="የብድር መመለሻ ግዜ(ወር)" zvalue={loan?.numberOfRepayments} />

              <TblLoanRequest zkey="የብድር ወለድ" zvalue={loan.annualInterestRate} />
              <TblLoanRequest zkey="የአግልግሎት ክፍያ በ%" zvalue={loan.prcntServiceCharge} />
              <TblLoanRequest zkey="ብድር ፕሮሰስ የሚጀምሩበት ቀን" zvalue={loan?.daysToLoanProcess} />
              <TblLoanRequest zkey="በመ.ቁጠባ ብድር ይጨመራል" zvalue={loan.isAddedToLoanSaving ? loan?.toLoanSavingAddedAmount?.toLocaleString() + " ብር ይጨምራል " : "አይጨምርም "} />

            </View>
            <View style={{ width: "30%", padding: 3 }}>
              <Text style={ReportStyle.normalText}>
                ቅርንጫፍ
              </Text>
              <TextBold text={loan?.requestbranch?.amName} />
              <Text style={ReportStyle.normalText}>
                የብድር አላማ
              </Text>
              <Text style={ReportStyle.normalText}>
                የብድር አይነት
              </Text>
              <TextBold text={loan.loanType?.amName} />
              <Text style={ReportStyle.normalText}>
                የአባልነት ደረጃ
              </Text>
              <TextBold text={loan.savingdecipline} />
              <Text style={ReportStyle.normalText}>
                ጾታ
              </Text>
              <TextBold text={customer?.gender} />
              <Text style={ReportStyle.normalText}>
                የጋብቻ ሁኔታ
              </Text>
              <TextBold text={customer?.isMarried ? "ያገባ" : "ያላገባ"} />
              <Text style={ReportStyle.normalText}>
                የትምህርት ደረጃ
              </Text>

            </View>
          </View>


          <View style={{
            display: "flex",
            flexDirection: "row",
            borderBottomWidth: 1,
            borderBottomColor: "#FF7700",
            borderBottomStyle: "solid",
            justifyContent: "space-between",
          }}></View>

          <View style={{
            display: "flex",
            flexDirection: "row",
            width: "100%"
          }}>
            <View style={{ width: "70%" }}>
              <TblLoanRequest zkey="የሂወት መድህን ክፍያ %" zvalue={life_insurance(loan)} />
              <TblLoanRequest zkey="የአግልግሎት እና ኢንሹራንስ ክፍያ" zvalue={flat_service_and_life_insurance(loan)?.toLocaleString()} />
              <TblLoanRequest zkey="ለብድር መቀነሻ የሚሆን ቁጠባ" zvalue={Savings_for_loan_deduction(loan)?.toLocaleString()} />
              <TblLoanRequest zkey="ዋናው ብድር(principal)" zvalue={calculate_principal(loan)?.toLocaleString()} />
            </View>
            <View style={{ display: "flex", flexDirection: "column", width: "30%", justifyContent: "center", alignItems: "center" }}>
              <Text style={ReportStyle.name}> የብድር ሂደት የሚጀመርበት ቀን </Text>
              <Text> March 12, 2025 </Text>
            </View>
          </View>


          <View style={{
            display: "flex",
            flexDirection: "row",
            borderBottomWidth: 1,
            borderBottomColor: "#FF7700",
            borderBottomStyle: "solid",
            justifyContent: "space-between",
          }}></View>


          <TblLoanRequest zkey="ተበዳሪ እጅላይ የሚደርስ ብር" zvalue={(loan.approvedPrincipal + loan.toLoanSavingAddedAmount)?.toLocaleString()} />
          <TblLoanRequest zkey="ወርሃዊ ክፍያ" zvalue={loan?.newTotalDueForPeriod?.toLocaleString()} />
          <TblLoanRequest zkey="ጠቅላላ የወለድ መጠን" zvalue={Calculate_totalInterest(loan)?.toLocaleString()} />
          <TblLoanRequest zkey="መቅረብ ያለበት የዋስትና መጠን" zvalue={(Calculate_totalCollateral(loan) + loan?.approvedPrincipal)?.toLocaleString()} />
          <View style={{ marginTop: 10 }}></View>
          <View>
            <Text style={ReportStyle.normalText}>
              ተበዳሪ አስፈላጊ የብድር ሰነዶችን ማሟላት የሚጀምረው የወረፋ ጊዜ ከተጠናቀቀ በኋላ ይሆናል፡፡ ማንኛውም ተበዳሪ የንብረት የግዥ ውል የሚዋዋለው በአሚጎስ ከተገመተ በኋላ ይሆናል፡፡
              የብድር አስፈላጊ ሰነዶች ካሟሉ በኋላ ለብድር ውል ዝግጅት /LOAN PROCESS/ ይደረጋል፡፡
              ከብድር ውል የመጨረሻ ፊርማ በኋላ 15 የስራ ቀናት ለብድር ኮሚቴ ፊርማ እንዲሁም ለቼክ ዝግጅት /LOAN DISBURSEMENT/ ተበዳሪው ካላቸው የቁጠባ ተቀማጭ ባልተከፈለ (ዋናው)
              ብድር በየአመቱ 2% የብድር ኢንሹራንስ ለመክፈል ይገደዳሉ፡፡ ይህም የብድር መመለሻ አመቱ ሳይደርስ ብድር የሚመልስ ከሆነ የብድር ኢንሹራንስ የማይቆረጥበት ይሆናል፡፡ አመታዊ ኢንሹራንስ ክፍያው 0.5%
              ከአባሉ አጠቃላይ ተቀማጭ ሂሳብ (አክሲዎን ፣ መደበኛ ቁጠባ ፣ ፍቃደኝነት ቁጠባ) በአመት አንዴ አንድ አባል ብድር ጠይቆ የብድር ቀጠሮ ቀኑ ደርሶ የብድር ሂደት ሳይጀምር 6 ወር ካለፈው ብድሩ ይቋረጣል፡፡
            </Text>
          </View>
          <View style={{
            display: 'flex',
            textAlign: 'center',
            padding: 2,
            backgroundColor: "gray",
            marginTop: 10
          }}>
            <Text style={ReportStyle.normalText}> በብድር ጥያቄው ላይ የተሰጠ ማብራርያ  </Text>
          </View>

          <View style={{ marginTop: 65 }}></View>

          <View style={{
            display: 'flex',
            textAlign: 'center',
            padding: 2,
            backgroundColor: "gray"
          }}>
            <Text style={ReportStyle.normalText}> ማህበሩ በማንኛውም ሰአት ማንኛውንም ማሻሻያ ሊያደርግ ይችላል፡፡ ይህም በማንኛውም አባል ላይ ተፈጻሚነት ይኖረዋል፡፡ </Text>

          </View>
          <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Text style={ReportStyle.normalText}> የውስጥ ኦዲት ክፍል  </Text>
            <Text style={ReportStyle.normalText}> ቅጹን ያስሞላው/ችው፦   </Text>
            <Text style={ReportStyle.normalText}> ያረጋገጠው/ችው፦  </Text>
          </View>
          <View style={{ marginTop: 30 }}></View>

          <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Text style={ReportStyle.normalText}>   </Text>
            <Text style={ReportStyle.normalText}> ያጸደቀው/ችው፦   </Text>
            <Text style={ReportStyle.normalText}> የብድር ጠያቂው ስምና ፊርማ፦  </Text>
          </View>
          <View style={{ marginTop: 30 }}></View>

          <View style={{
            display: 'flex',
            textAlign: 'center',
            padding: 2,
            backgroundColor: "gray"
          }}>
            <Text style={ReportStyle.normalText}> እኔ ስሜ ብድር ጠያቂ ተብሎ የተጠቀሰው የማህበሩ አባል ስሆን ከላይ በተጠቀሰው መሰረት ብድር እንዲፈቀድልኝ ስል አመለክታለው፡፡  </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default AmLoanRequest