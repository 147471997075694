import React, { useEffect, useState } from 'react'
import axiosInstance from '../axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FaCarAlt, FaEye, FaFileAlt } from 'react-icons/fa';
import { Accordion, Alert, Badge, Button, Card, InputGroup, Modal, Table } from 'react-bootstrap';
import { Field, Form, Formik } from 'formik';
import CarSchema from '../Schema/CarSchema';
import InputField from '../ReusableFormiks/InputField';
import { setTargetLoan } from '../Common/redux/systemLookups';
// import { singleCarPrice } from '../Common/modules/FiveCChecker';
import { singleCarPrice } from '../Common/modules/NewFiveCSums';

function Car({ successnotify, errornotify, targetLoan }) {
    const { data } = useSelector(store => store.customer);
    const { serverIP } = useSelector(store => store.allsettings);
    // const { targetLoan } = useSelector(store => store.systemLookups);
    const dispatch = useDispatch();
    const { loanId } = useParams();

    const [carModels, setCarModels] = useState([]);
    const [carManufactureYear, setCarManufactureYear] = useState([]);
    const [ddlcoutry, setsddlcoutry] = useState([]);

    const [regions, setRegions] = useState([]);
    const [zones, setZones] = useState([]);
    const [woredas, setWoredas] = useState([]);
    const [editingRecord, setEditingRecord] = useState(null);


    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [targetRecord, setTargetRecord] = useState({})
    const [file, setFile] = useState(null);

    const [gpModal, setGpModal] = useState(false);
    const [gpid, setGpid] = useState(null);
    const [show, setShow] = useState(false);


    const [isChinaProduct, setIsChinaProduct] = useState(false);
    const [isBtnSaveClicked, setIsBtnSaveClicked] = useState(false);

    useEffect(() => {
        getCarModels();
        getCarManufactureYears();
    }, [isChinaProduct]);

    const baseStyle = "block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 py-1 px-1 text-base"

    const formatNumber = (number) => {
        if (!number) return "";
        return new Intl.NumberFormat().format(number);
    };


    useEffect(() => {
        getManufacturedCountry();
        getregions();
        getzones();
        getworedas();
        getCarModels();
        getCarManufactureYears();
    }, []);

    const getManufacturedCountry = () => {
        axiosInstance
            .get(`${serverIP}/ddlcountry/ddlcountries`)
            .then(res => {
                setsddlcoutry(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const getregions = () => {
        axiosInstance
            .get(`${serverIP}/regions/regions/activeregions`)
            .then(res => {
                setRegions(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const getzones = () => {
        axiosInstance
            .get(`${serverIP}/zones/zone`)
            .then(res => {
                setZones(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const getworedas = () => {
        axiosInstance
            .get(`${serverIP}/woredas/woreda`)
            .then(res => {
                setWoredas(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const getCarModels = () => {
        let param = isChinaProduct ? 1 : 0
        axiosInstance
            .get(`${serverIP}/car_model/ischina/${param}`)
            .then(res => {
                setCarModels(res.data);
                console.log(res.data)
            })
            .catch(err => {
                console.log(err);
            });
    };

    const getCarManufactureYears = () => {
        let param = isChinaProduct ? 1 : 0
        axiosInstance
            .get(`${serverIP}/car_manufacturer/ischina/${param}`)
            .then(res => {
                console.log(res.data)
                setCarManufactureYear(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    };


    const initialValues = {
        chassisNumber: '',
        engineNumber: '',
        librebookid: '',
        carPlate: '',
        carCC: '',

        insuranceValue: '',
        vehiclePerformance: '',
        garageValue: '',
        sanctionAddress: '',
        loan: loanId,

        manufacturedYear: '',
        manufacturedCountry: '',
        model: '',
        regionId: '',
        zoneId: '',

        woredaId: '',
        serialnum: '',
    };

    const handleSubmit = (values, { resetForm }) => {
        console.log('handle submit')
        console.log(values)
        if (editingRecord) {
            // Update existing user
            axiosInstance
                .patch(`${serverIP}/collateral_car/collateralcar/${editingRecord.id}`, {
                    manufacturedYear: values.manufacturedYear,
                    chassisNumber: values.chassisNumber,
                    engineNumber: values.engineNumber,
                    librebookid: values.librebookid,
                    carPlate: values.carPlate,
                    carCC: values.carCC,
                    loan: loanId,
                    manufacturedCountry: values.checkListId,
                    model: values.model,
                    insuranceValue: values.insuranceValue,
                    garageValue: values.garageValue,
                    serialnum: values.serialnum,
                    vehiclePerformance: values.vehiclePerformance,

                    regionId: values.regionId,
                    zoneId: values.zoneId,
                    woredaId: values.woredaId,
                    sanctionAddress: values.sanctionAddress,
                    manufacturedCountry: values.manufacturedCountry
                })
                .then(res => {
                    successnotify();
                    setShowAddModal(false);
                    setIsBtnSaveClicked(false)
                    getLoan();
                    console.log(res.data)
                })
                .catch(err => {
                    errornotify();
                    console.log(err)
                });
            getLoan();

            setEditingRecord(false);
            setShowAddModal(false)

        } else {
            axiosInstance
                .post(`${serverIP}/collateral_car/collateralcar`, {
                    manufacturedYear: values.manufacturedYear,
                    chassisNumber: values.chassisNumber,
                    engineNumber: values.engineNumber,
                    librebookid: values.librebookid,
                    carPlate: values.carPlate,
                    carCC: values.carCC,
                    loan: loanId,
                    manufacturedCountry: values.checkListId,
                    model: values.model,
                    insuranceValue: values.insuranceValue,
                    garageValue: values.garageValue,
                    serialnum: values.serialnum,
                    vehiclePerformance: values.vehiclePerformance,

                    regionId: values.regionId,
                    zoneId: values.zoneId,
                    woredaId: values.woredaId,
                    sanctionAddress: values.sanctionAddress,
                    manufacturedCountry: values.manufacturedCountry
                })
                .then(res => {
                    successnotify();
                    setShowAddModal(false);
                    setIsBtnSaveClicked(false)
                    getLoan();
                    console.log(res.data)
                })
                .catch(err => {
                    errornotify();
                    console.log(err)
                });
            getLoan();
            setShowAddModal(false)

        }
        setShowAddModal(false)
        resetForm();
    };

    const getLoan = () => {
        axiosInstance
            .get(`${serverIP}/loan/loans/${targetLoan.id}`)
            .then(res => {
                dispatch(
                    setTargetLoan({
                        targetLoan: res.data
                    })
                );
            })
            .catch(err => {
                console.log(err);
            });
    };


    const deleteCar = () => {
        axiosInstance
            .delete(`${serverIP}/collateral_car/collateralcar/${targetRecord.id}`)
            .then(res => {
                setShowDeleteModal(false);
                getLoan();
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleUpload = async targetfile => {
        if (!file) {
            return;
        }

        try {
            const fd = new FormData();
            fd.append('file', file);
            fd.append('parent', targetfile.parent);
            fd.append('fileType', file.type);
            fd.append('fileUrl', file);
            fd.append('isUploaded', true);

            const fileuploaded = await axiosInstance.patch(`${serverIP}/car_commonfiles/carfiles/${targetfile.id}`, fd, {
                headers: {
                    'Custom-Header': 'value'
                }
            });
        } catch (error) {
            console.log('error uploading file');
            console.log(error)
        } finally {
            getLoan();
            // getCarFiles();
        }
    };

    // /car_commonfiles/carfiles/{id}

    const changeGP = () => {
        axiosInstance
            .patch(`${serverIP}/collateral_car/collateralcar/${targetRecord.id}`, {
                cargp: gpid
            })
            .then(res => {
                successnotify();
                setGpModal(false);

                getLoan();
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleDeleteFile = item => {
        axiosInstance
            .patch(`${serverIP}/car_commonfiles/carfiles/${item.id}`, {
                isUploaded: false
            })
            .then(res => {
                successnotify();
                getLoan();
            })
            .catch(err => {
                errornotify();
                console.log(err);
            });
    };


    return (
        <div>
            <Alert className="px-3 flex flex-row justify-between py-1 items-center">
                <div style={{ fontSize: 20, fontWeight: 'bold', color: 'GrayText' }}>የመኪና መያዣ</div>
                {data.groups[0] === 'ROLE_OFFICER' && (
                    <Button
                        onClick={() => setShowAddModal(true)}
                        className="flex flex-row items-center gap-2"
                        disabled={targetLoan?.loanstatus?.id !== 1}
                    >
                        <FaCarAlt /> ጨምር
                    </Button>
                )}
            </Alert>
            {/* modal start  */}

            {/* Modal Show Start  */}
            <Modal size="lg" show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> {targetRecord.amDesc} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={serverIP + targetRecord.fileUrl} style={{ width: '100%', height: '100%' }} />
                </Modal.Body>
            </Modal>
            {/* Modal Show End  */}

            {/* change guarantee person modal Start  */}
            <Modal show={gpModal} onHide={() => setGpModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> ዋስ ግለሰብ ቀይር </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <select
                        className="form-select"
                        onChange={e => {
                            setGpid(e.target.value);
                            console.log(e.target.value);
                        }}
                    >
                        <option value="null" selected>
                            ተበዳሪ
                        </option>
                        {targetLoan.gp?.length > 0 &&
                            targetLoan.gp.map(gp => {
                                return (
                                    <option key={gp.id} value={gp.id}>
                                        {gp.fullname}
                                    </option>
                                );
                            })}
                    </select>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning btn-sm" onClick={() => changeGP()}>
                        Change
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* change guarantee person modal End  */}

            {/* add modal Start  */}
            <Modal size='lg' show={showAddModal} onHide={() => setShowAddModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> አዲስ መኪና መያዣ </Modal.Title>
                </Modal.Header>
                <div className="flex justify-center items-center">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={e => {
                                setIsChinaProduct(e.target.checked);
                            }}
                        />
                        <label className="form-check-label"> የቻይና ምርት ነው </label>
                    </div>
                </div>
                <Formik
                    initialValues={editingRecord || initialValues}
                    validationSchema={CarSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({ values, errors, touched, handleChange, setFieldValue }) => (
                        <Form>
                            <Modal.Body className='flex w-full gap-1'>
                                <Card className="p-1 w-1/2">

                                    <div className="mb-1">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            የመኪና ሞዴል
                                        </label>
                                        <Field
                                            as="select"
                                            name="model"
                                            className={baseStyle}
                                        >
                                            <option value="">የመኪና ሞዴል ይምረጡ </option>
                                            {carModels.map(model => (
                                                <option key={model.id} value={model.id}>
                                                    {model.enName}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>
                                    <div className="mb-1">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            የተመረተበት ዘመን
                                        </label>
                                        <Field
                                            as="select"
                                            name="manufacturedYear"
                                            className={baseStyle}
                                        >
                                            <option value="">የተመረተበት ዘመን ይምረጡ</option>
                                            {carManufactureYear.map(manufactureYear => (
                                                <option key={manufactureYear.id} value={manufactureYear.id}>
                                                    {manufactureYear.yearRange}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>

                                    <div className="mb-1">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            የተመረተበት ሀገር
                                        </label>
                                        <Field
                                            as="select"
                                            name="manufacturedCountry"
                                            className={baseStyle}
                                        >
                                            <option value="">የተመረተበት ሀገር  ይምረጡ</option>
                                            {ddlcoutry.map(ctry => (
                                                <option key={ctry.id} value={ctry.id}>
                                                    {ctry.amName}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>


                                    <div className="mb-1">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Region
                                        </label>
                                        <Field
                                            as="select"
                                            name="regionId"
                                            onChange={(e) => {
                                                handleChange(e);
                                                setFieldValue('zoneId', '');
                                                setFieldValue('woredaId', '');
                                            }}
                                            className={baseStyle}
                                        >
                                            <option value="">Select Region</option>
                                            {regions.map(region => (
                                                <option key={region.id} value={region.id}>
                                                    {region.amDescription}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>

                                    <div className="mb-1">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Zone
                                        </label>
                                        <Field
                                            as="select"
                                            name="zoneId"
                                            onChange={(e) => {
                                                handleChange(e);
                                                setFieldValue('woredaId', '');
                                            }}
                                            disabled={!values.regionId}
                                            className={` ${baseStyle} ${!values.regionId ? 'bg-gray-100 cursor-not-allowed' : ''}`}
                                        >
                                            <option value="">Select Zone</option>
                                            {zones
                                                .filter(zone => zone.parent == values.regionId)
                                                .map(zone => (
                                                    <option key={zone.id} value={zone.id}>
                                                        {zone.amDescription}
                                                    </option>
                                                ))}
                                        </Field>
                                    </div>

                                    <div className="mb-1">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Woreda
                                        </label>
                                        <Field
                                            as="select"
                                            name="woredaId"
                                            disabled={!values.zoneId}
                                            className={`${baseStyle} ${!values.zone ? 'bg-gray-100 cursor-not-allowed' : ''}`}
                                        >
                                            <option value="">Select Woreda</option>
                                            {woredas
                                                .filter(woreda => woreda.parent == values.zoneId)
                                                .map(woreda => (
                                                    <option key={woreda.id} value={woreda.id}>
                                                        {woreda.amDescription}
                                                    </option>
                                                ))}
                                        </Field>
                                    </div>
                                </Card>
                                <Card className="p-1 w-1/2">
                                    <div className='flex gap-2'>
                                        <InputField
                                            label="የሊብሬ ቁጥር "
                                            name="librebookid"
                                            placeholder="የሊብሬ ቁጥር"
                                            touched={touched.librebookid}
                                            errors={errors.librebookid}
                                        /> <InputField
                                            label="የቻሲስ ቁጥር"
                                            name="chassisNumber"
                                            placeholder="የቻሲስ ቁጥር"
                                            touched={touched.chassisNumber}
                                            errors={errors.chassisNumber}
                                        />
                                    </div>

                                    <div className='flex gap-2'>
                                        <InputField
                                            label="ታርጋ ቁጥር"
                                            name="carPlate"
                                            placeholder="ታርጋ ቁጥር"
                                            touched={touched.carPlate}
                                            errors={errors.carPlate}
                                        /> <InputField
                                            label="የኢንጅን ቁጥር"
                                            name="engineNumber"
                                            placeholder="የኢንጅን ቁጥር"
                                            touched={touched.engineNumber}
                                            errors={errors.engineNumber}
                                        />
                                    </div>
                                    <div className='flex gap-2'>
                                        <InputField
                                            label="የጋራጅ ግምት"
                                            name="garageValue"
                                            placeholder="የጋራጅ ግምት"
                                            touched={touched.garageValue}
                                            errors={errors.garageValue}
                                        /> <InputField
                                            label="የኢንሹራንስ ግምት"
                                            name="insuranceValue"
                                            placeholder="የኢንሹራንስ ግምት"
                                            touched={touched.insuranceValue}
                                            errors={errors.insuranceValue}
                                        />
                                    </div>
                                    <div className='flex gap-2'>
                                        <InputField
                                            label="የመኪና CC"
                                            name="carCC"
                                            placeholder="የመኪና CC"
                                            touched={touched.carCC}
                                            errors={errors.carCC}
                                        />
                                        <InputField
                                            label="የተሽከርካሪ ብቃት"
                                            name="vehiclePerformance"
                                            placeholder="የተሽከርካሪ ብቃት"
                                            touched={touched.vehiclePerformance}
                                            errors={errors.vehiclePerformance}
                                        />
                                    </div>
                                    <InputField
                                        label="ሴርያል ቁጥር "
                                        name="serialnum"
                                        placeholder="ሴርያል ቁጥር "
                                        touched={touched.serialnum}
                                        errors={errors.serialnum}
                                    />
                                    <InputField
                                        label="የእግድ አድራሻ"
                                        name="sanctionAddress"
                                        placeholder="የእግድ አድራሻ"
                                        touched={touched.sanctionAddress}
                                        errors={errors.sanctionAddress}
                                    />
                                    {/* sanctionAddress */}

                                </Card>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button type="submit" variant={editingRecord ? "warning" : "primary"} >
                                    {editingRecord ? 'አስተካክል ' : 'አስቀምጥ '}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>

            </Modal>
            {/* add modal end  */}

            {/* delete modal Start  */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> በመያዣነት የቀረበው መኪና አጥፋ </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>{targetRecord?.model?.enName}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger btn-sm" onClick={() => deleteCar()}>
                        አጥፋ
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* delete modal End  */}

            {/* modal end  */}

            <div className="row">
                <div className="col-sm-12" style={{ paddingTop: 5 }}>
                    {Object.keys(targetLoan)?.length > 0 ? (
                        <Accordion defaultActiveKey="1">
                            {targetLoan.collateralcar.map(car => {
                                return (
                                    <Accordion.Item eventKey={car.id}>
                                        <Accordion.Header>
                                            <div className="flex flex-row items-center gap-2">
                                                <FaFileAlt />
                                                <Badge>
                                                    {car.carfiles?.filter(x => x.isUploaded === true)?.length}/ {car.carfiles?.length}
                                                </Badge>
                                                {car.chassisNumber + ' - ' + car.engineNumber + ' - ' + car.carPlate}
                                                <Badge>
                                                    {singleCarPrice(car)?.toLocaleString()}
                                                </Badge>
                                                {car.model?.enName + ' - ' + car.manufacturedCountry?.amName}
                                                <Badge>
                                                    {car.cargp !== null ? car.cargp?.fullname : 'የተበዳሪ'}
                                                </Badge>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="flex flex-row p-2 justify-between bg-slate-500 mb-1 text-white">
                                                <span>{car.cargp !== null ? car.cargp?.fullname : 'የተበዳሪ'}</span>
                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                    <Button
                                                        onClick={() => {
                                                            setEditingRecord({
                                                                chassisNumber: car.chassisNumber,
                                                                engineNumber: car.engineNumber,
                                                                librebookid: car.librebookid,
                                                                id: car.id,

                                                                carPlate: car.carPlate,
                                                                carCC: car.carCC,
                                                                insuranceValue: car.insuranceValue,

                                                                vehiclePerformance: car.vehiclePerformance,
                                                                garageValue: car.garageValue,
                                                                sanctionAddress: car.sanctionAddress,
                                                                serialnum: car.serialnum,
                                                                loan: loanId,
                                                                manufacturedYear: car?.manufacturedYear?.id,
                                                                manufacturedCountry: car?.manufacturedCountry?.id,

                                                                model: car?.model?.id,
                                                                regionId: car?.regionId?.id,
                                                                zoneId: car?.zoneId?.id,
                                                                woredaId: car?.woredaId?.id,
                                                            });
                                                            setShowAddModal(true);
                                                            console.log("selected car");
                                                            console.log(car);
                                                        }}
                                                        className="btn btn-sm btn-warning"
                                                        disabled={targetLoan?.loanstatus?.id !== 1}
                                                    >
                                                        አስተካክል
                                                    </Button>
                                                    <button
                                                        onClick={() => {
                                                            setGpModal(true);
                                                            setTargetRecord(car);
                                                        }}
                                                        type="button"
                                                        className="btn btn-sm btn-primary"
                                                        disabled={targetLoan?.loanstatus?.id !== 1}
                                                    >
                                                        ተያዥ ቀይር
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            setShowDeleteModal(true);
                                                            setTargetRecord(car);
                                                        }}
                                                        className="btn btn-sm btn-danger"
                                                        disabled={targetLoan?.loanstatus?.id !== 1}
                                                    >
                                                        አጥፋ
                                                    </button>
                                                </div>
                                            </div>

                                            {car.carfiles?.length > 0 ?
                                                <>
                                                    <Alert
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between'
                                                        }}
                                                        className="mt-2 py-2 items-center"
                                                    >
                                                        <div>በመኪና ዋስትና የሚሰጥ የብድር ሰነድ ማረጋገጫ ዝርዝር</div>
                                                        <Button
                                                            className="btn btn-warning btn-sm"
                                                            disabled={targetLoan?.loanstatus?.id !== 1}
                                                            onClick={() => {
                                                                setTargetRecord(car);
                                                                // reLoadData();
                                                            }}
                                                        >
                                                            የፋይል ዝርዝር አጥፋ
                                                        </Button>
                                                    </Alert>

                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th> የፋይል ስም </th>
                                                                <th> እይ </th>
                                                                <th> ድርጊት </th>
                                                                <th> አጥፋ </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {car.carfiles?.map(upload => {
                                                                return (
                                                                    <tr key={upload.id} className={upload.isUploaded ? `` : `table-warning`}>
                                                                        <td>{upload.amDesc}</td>
                                                                        <td>
                                                                            {upload.isUploaded ? (
                                                                                <>
                                                                                    {upload.fileType === 'application/pdf' ? (
                                                                                        <a
                                                                                            target="_blank"
                                                                                            href={serverIP + upload.fileUrl}
                                                                                            rel="noopener noreferrer"
                                                                                        >
                                                                                            <FaFileAlt />
                                                                                        </a>
                                                                                    ) : (
                                                                                        <FaEye
                                                                                            onClick={() => {
                                                                                                setShow(true);
                                                                                                setTargetRecord(upload);
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </td>

                                                                        <td style={{ width: '30%', padding: 0 }}>
                                                                            {data.groups[0] === 'ROLE_OFFICER' ? (
                                                                                <InputGroup style={{ paddingTop: 5 }}>
                                                                                    <input
                                                                                        onChange={e => {
                                                                                            setFile(e.target.files[0]);
                                                                                            console.log(e.target.files[0]);
                                                                                        }}
                                                                                        type="file"
                                                                                        className="form-control"
                                                                                    />
                                                                                    <Button disabled={targetLoan?.loanstatus?.id !== 1} variant="outline-primary" onClick={() => handleUpload(upload)}>
                                                                                        {!upload.isUploaded ? <> ጫን </> : <>አዘምን</>}
                                                                                    </Button>
                                                                                </InputGroup>
                                                                            ) : (
                                                                                <>{upload.isUploaded ? 'ተጭኗል' : ' '}</>
                                                                            )}
                                                                        </td>
                                                                        <td style={{ width: '10%', padding: 0 }}>
                                                                            {upload.isUploaded && (
                                                                                <Button disabled={targetLoan?.loanstatus?.id !== 1} variant="outline-danger" onClick={() => handleDeleteFile(upload)}>
                                                                                    አጥፋ
                                                                                </Button>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}

                                                        </tbody>
                                                    </Table>
                                                </>
                                                : <></>}

                                        </Accordion.Body>
                                    </Accordion.Item>
                                );
                            })}
                        </Accordion>
                    ) : (
                        <></>
                    )}
                </div>
            </div>

            {/* accordion end  */}

        </div>
    )
}

export default Car