function Sum_Cogs(loanFinanceItems) {
  if (!loanFinanceItems) return 0;
  const costofgoods = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 1 ? obj.markValue : 0);
  }, 0);
  return Number(costofgoods.toFixed(2));
}

function Sum_Operating_Expenses(loanFinanceItems) {
  // if (!loanFinanceItems) return 0;
  const operatingExpense = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 2 ? obj.markValue : 0);
  }, 0);
  return Number(operatingExpense.toFixed(2));
}

function Sum_Interest_Expenses(loanFinanceItems) {
  // if (!loanFinanceItems) return 0;
  const interestExpenses = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 3 ? obj.markValue : 0);
  }, 0);
  return Number(interestExpenses.toFixed(2));
}

function Sum_Tax_Expenses(loanFinanceItems) {
  // if (!loanFinanceItems.length > 0) return 0;
  const taxExpenses = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 4 ? obj.markValue : 0);
  }, 0);
  return Number(taxExpenses.toFixed(2));
}

function Sum_Personal_Expenses(loanFinanceItems) {
  // if (!loanFinanceItems) return 0;
  const personalExpenses = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 5 ? obj.markValue : 0);
  }, 0);
  return Number(personalExpenses.toFixed(2));
}

function Sum_Incomes(loanFinanceItems) {
  // if (!loanFinanceItems) return 0;
  const incomes = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 6 ? obj.markValue : 0);
  }, 0);
  return Number(incomes.toFixed(2));
}

function Sum_Assets(loanFinanceItems) {
  // if (!loanFinanceItems) return 0;
  const assets = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 7 ? obj.markValue : 0);
  }, 0);
  return Number(assets.toFixed(2));
}

function Sum_Liabilities(loanFinanceItems) {
  if (!loanFinanceItems) return 0;
  const liabilities = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 8 ? obj.markValue : 0);
  }, 0);
  return Number(liabilities.toFixed(2));
}

function Sum_Capitals(loanFinanceItems) {
  if (!loanFinanceItems) return 0;
  const capitals = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 9 ? obj.markValue : 0);
  }, 0);
  return Number(capitals.toFixed(2));
}

function Monthly_Basic_Expenses(loanFinanceItems) {
  if (!loanFinanceItems) return 0;
  const capitals = loanFinanceItems.reduce((accum, obj) => {
    return accum + ((obj.category_id == 1 || obj.category_id == 2 || obj.category_id == 3 || obj.category_id == 4 || obj.category_id == 5) ? obj.markValue : 0);
  }, 0);
  return Number(capitals.toFixed(2));
}

function Sum_MaintenanceRelatedExpenses(loanFinanceItems) {
  if (!loanFinanceItems) return 0;
  const capitals = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 10 ? obj.markValue : 0);
  }, 0);
  return Number(capitals.toFixed(2));
}

function Sum_FinancialExpenses(loanFinanceItems) {
  if (!loanFinanceItems) return 0;
  const capitals = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 11 ? obj.markValue : 0);
  }, 0);
  return Number(capitals.toFixed(2));
}

function Sum_OtherExpenses(loanFinanceItems) {
  if (!loanFinanceItems) return 0;
  const capitals = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 12 ? obj.markValue : 0);
  }, 0);
  return Number(capitals.toFixed(2));
}

function calculateIRR(cashFlows) {
  if (!Array.isArray(cashFlows) || cashFlows.length < 2) {
    throw new Error("Cash flows must be an array with at least 2 values");
  }

  const guess = 0.1;
  const maxIterations = 100;
  const precision = 1e-6;

  let rate = guess;
  for (let i = 0; i < maxIterations; i++) {
    const npv = cashFlows.reduce((acc, cf, index) => acc + cf / Math.pow(1 + rate, index), 0);
    const dNpv = cashFlows.reduce(
      (acc, cf, index) => acc - (index * cf) / Math.pow(1 + rate, index + 1),
      0
    );

    if (dNpv === 0) {
      throw new Error("Division by zero encountered");
    }

    const newRate = rate - npv / dNpv;
    if (Math.abs(newRate - rate) < precision) {
      return Number(newRate.toFixed(2));
    }
    rate = newRate;
  }

  throw new Error("IRR did not converge");
}

function DebtServiceCoverageRatio(loanFinanceItems) {
  if (!loanFinanceItems) return 0;
  const capitals = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.id == 19 || obj.id == 24 ? obj.markValue : 0);
  }, 0);
  return Number(capitals.toFixed(2));
}

function DebtServiceCoverageRatioNew(targetLoan, loanFinanceItems) {
  if (!targetLoan || !loanFinanceItems || !targetLoan.totalDueForPeriod) return 0;

  const capitals = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.id == 19 || obj.id == 24 ? obj.markValue : 0);
  }, 0);

  let maintenanceExp = Sum_MaintenanceRelatedExpenses(targetLoan.loan_fonance_items);
  let diff = capitals - maintenanceExp;

  return Number((diff / targetLoan.totalDueForPeriod).toFixed(2));
}

function NetProfit(targetLoan) {
  if (!targetLoan || !targetLoan.loan_fonance_items) return 0;

  let businessGrossIncome = Sum_Incomes(targetLoan.loan_fonance_items);
  let totalExpenses = Monthly_Basic_Expenses(targetLoan.loan_fonance_items);

  return Number((businessGrossIncome - totalExpenses).toFixed(2));
}

function Debt_To_Income_Ratio(targetLoan) {
  if (!targetLoan || !targetLoan.loan_fonance_items || !targetLoan.totalDueForPeriod) return 0;

  let incomes = Sum_Incomes(targetLoan.loan_fonance_items);
  let monthlyExpenses = ((Monthly_Basic_Expenses(targetLoan.loan_fonance_items) + 5000) * 105) / 100;

  if (incomes - monthlyExpenses === 0) return 0;

  return Number((targetLoan.totalDueForPeriod * 100 / (incomes - monthlyExpenses)).toFixed(2));
}
function collateralSum(targetLoan) {
  if (!targetLoan) return 0;
  const sum_car =
    targetLoan?.collateralcar?.length > 0
      ? targetLoan?.collateralcar?.reduce((accum, obj) => {
        if (!obj) return accum;
        return (accum + singleCarPrice(obj));
      }, 0)
      : 0;

  const sum_home =
    targetLoan?.collateralhome?.length > 0
      ? targetLoan?.collateralhome?.reduce((accum, home) => {
        return accum + singleHomePrice(home);;
      }, 0)
      : 0;

  const sum_salary =
    targetLoan?.collateralemployee?.length > 0
      ? targetLoan?.collateralemployee?.reduce((accum, obj) => {
        if (!obj) return accum;
        return accum + ((obj?.grossSalary || 0) * 100) / 15;
      }, 0)
      : 0;

  const sum_stock =
    targetLoan?.collateralstock?.length > 0
      ? targetLoan?.collateralstock?.reduce((accum, obj) => {
        if (!obj) return accum;
        return accum + (obj?.priceperstock || 0) * (obj?.stockqty || 0);
      }, 0)
      : 0;

  let res = sum_car + sum_home + sum_salary + sum_stock;
  return res
}

function singleCarPrice(car) {
  let garage_ins_perf = (car?.insuranceValue * 0.68 + car?.garageValue) / 2 * car?.vehiclePerformance;
  let amigos_val = car?.manufacturedYear?.multiplierRatio * car?.carCC * car?.model.pricePerCc;
  let final_price = (garage_ins_perf + amigos_val) / 2;
  return final_price;
}


function singleHomePrice(home) {
  let single_home = home?.homearea * home?.location?.percentAllowed * home?.buildingtype?.percentAllowed;

  let use = single_home * (1 - home?.homeuse?.percentAllowed)
  let neighbour = single_home * (1 - home?.neighbour?.percentAllowed)
  let buildingmaterial = single_home * (1 - home?.buildingmaterial?.percentAllowed)
  let fromasphalt = single_home * (1 - home?.fromasphalt?.percentAllowed)
  let physicalappearance = single_home * (1 - home?.physicalappearance?.percentAllowed)
  let floorlocation = single_home * (1 - home?.floorlocation?.percentAllowed)
  let setback = single_home * (1 - home?.setback?.percentAllowed)
  let accessroadtosite = single_home * (1 - home?.accessroadtosite?.percentAllowed)
  let isFenceIsolation = home?.isFenceIsolation ? 0 : (single_home * 0.2)

  let deductionObj = {
    "use": use / 9,
    "neighbour": neighbour / 9,
    "buildingmaterial": buildingmaterial / 9,
    "fromasphalt": fromasphalt,
    "physicalappearance": physicalappearance / 9,
    "floorlocation": floorlocation / 9,
    "setback": setback / 9,
    "accessroadtosite": accessroadtosite / 9,
  }

  return single_home - (use + neighbour + buildingmaterial + fromasphalt + physicalappearance + accessroadtosite + isFenceIsolation) / 9
  // return 100
}

function LiquidityRatio(loanFinanceItems) {
  if (!loanFinanceItems) return 0;

  let TotalAsset = Sum_Assets(loanFinanceItems);
  let TotalLiabilities = Sum_Liabilities(loanFinanceItems);

  return Number((TotalLiabilities === 0 ? 1 : TotalAsset / TotalLiabilities).toFixed(2));
}

function NetWorth(loanFinanceItems) {
  if (!loanFinanceItems) return 0;

  let TotalAsset = Sum_Assets(loanFinanceItems);
  let TotalLiabilities = Sum_Liabilities(loanFinanceItems);

  return Number((TotalAsset - TotalLiabilities).toFixed(2));
}

function Debt_To_Asset_Ratio(loanFinanceItems) {
  if (!loanFinanceItems) return 0;

  let TotalAsset = Sum_Assets(loanFinanceItems);
  let TotalLiabilities = Sum_Liabilities(loanFinanceItems);

  return Number((TotalAsset === 0 ? 1 : TotalLiabilities / TotalAsset).toFixed(2));
}

function Loan_To_Value_Ratio(targetLoan) {
  if (!targetLoan || !targetLoan.approvedPrincipal) return 0;

  let collateralvalue = collateralSum(targetLoan);
  return Number((collateralvalue > 0 ? targetLoan.approvedPrincipal / collateralvalue : 1).toFixed(2));
}

function CapitalEvaluation(targetLoan) {
  if (!targetLoan || !targetLoan.loan_fonance_items || !targetLoan.approvedPrincipal) return 0;

  let loanFinanceItems = targetLoan.loan_fonance_items;
  let collateralvalue = collateralSum(targetLoan);

  const liabilities = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 8 ? obj.markValue : 0);
  }, 0);

  const assets = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 7 ? obj.markValue : 0);
  }, 0);

  if (assets === 0) return 0;

  let net_worth = assets - liabilities;
  let debt_to_asset_ratio = liabilities / assets;
  let ltv = collateralvalue > 0 ? targetLoan.approvedPrincipal / collateralvalue : 1;

  let net_worth_calculated = net_worth > 0 ? 0.1 : 0;
  let debt_to_asset_ratio_calculated = debt_to_asset_ratio < 0.5 ? 0.3 : 0.1;
  let ltv_calculated = ltv <= 8 ? 0.5 : 0.2;

  let sum = net_worth_calculated + debt_to_asset_ratio_calculated + ltv_calculated;

  return Number((sum * 100 / 3).toFixed(2));
}

function CharacterEvaluation(targetLoan) {
  if (!targetLoan || !targetLoan.tenureInMonths || !targetLoan.previousBusinessTenureInMonths) return 0;

  let tenureInMonths = targetLoan.tenureInMonths > 12 ? 10 : targetLoan.tenureInMonths < 6 ? 3 : 6;
  let previousBusinessTenureInMonths = targetLoan.previousBusinessTenureInMonths > 6 ? 10 : 5;

  let businessOwnership = targetLoan.businessOwnership_mark || 0;
  let savingdecipline = targetLoan.savingdecipline_mark || 0;
  let referenceinamigos = targetLoan.referenceinamigos_mark || 0;
  let lastLoanGoodHistory = targetLoan.lastLoanGoodHistory ? 10 : 7;

  return Number(((40 + tenureInMonths + previousBusinessTenureInMonths + businessOwnership + savingdecipline + referenceinamigos + lastLoanGoodHistory) / 20).toFixed(2));
}

function min_dir_dscr_irr(targetLoan, loanFinanceItems) {
  if (!targetLoan || !loanFinanceItems || !loanFinanceItems[35]?.markValue || !loanFinanceItems[36]?.markValue) return 0;

  let Edited_DIR = 4500 / Debt_To_Income_Ratio(targetLoan);
  let Edited_DSCR = 100 * DebtServiceCoverageRatioNew(targetLoan, loanFinanceItems);
  let Edited_IRR = 10 * calculateIRR([loanFinanceItems[35].markValue, loanFinanceItems[36].markValue]);

  return Number(Math.min(Edited_DIR, Edited_DSCR, Edited_IRR).toFixed(2));
}

function dir_vs_dscd(targetLoan, loanFinanceItems) {
  if (!targetLoan || !loanFinanceItems) return 0;

  let Edited_DIR = 4500 / Debt_To_Income_Ratio(targetLoan);
  let Edited_DSCR = 100 * DebtServiceCoverageRatioNew(targetLoan, loanFinanceItems);

  let result = 0;
  if (Edited_DIR > 100) {
    result = 50;
  } else if (Edited_DSCR > 100) {
    result = 50;
  }
  return Number(result.toFixed(2));
}

function finalAbilityToPay(targetLoan, loanFinanceItems) {
  if (!targetLoan || !loanFinanceItems || !loanFinanceItems[35]?.markValue || !loanFinanceItems[36]?.markValue) return 0;

  let Edited_DIR = 4500 / Debt_To_Income_Ratio(targetLoan);
  let Edited_DSCR = 100 * DebtServiceCoverageRatioNew(targetLoan, loanFinanceItems);
  let Edited_IRR = 10 * calculateIRR([loanFinanceItems[35].markValue, loanFinanceItems[36].markValue]);

  let result = 0;
  if (Edited_DIR > 100) {
    result = 50;
  } else if (Edited_DSCR > 100) {
    result = 50;
  }

  let min = Math.min(Edited_DIR, Edited_DSCR, Edited_IRR);
  return Number((result + min).toFixed(2));
}

function firstC(targetClient) {
  if (!targetClient) return 0;

  const sum_single =
    targetClient?.singlegeneralfiles?.length > 0
      ? targetClient?.singlegeneralfiles?.reduce((accum, obj) => {
        return accum + (obj?.isUploaded ? obj?.markValue || 0 : 0);
      }, 0)
      : 0;

  const sum_married =
    targetClient?.marriedgeneralfiles?.length > 0
      ? targetClient?.marriedgeneralfiles?.reduce((accum, obj) => {
        return accum + (obj?.isUploaded ? obj?.markValue || 0 : 0);
      }, 0)
      : 0;

  const sum_general =
    targetClient?.customercommonfiles?.length > 0
      ? targetClient?.customercommonfiles?.reduce((accum, obj) => {
        return accum + (obj?.isUploaded ? obj?.markValue || 0 : 0);
      }, 0)
      : 0;

  let res = targetClient?.isMarried ? sum_married : sum_single;
  // let res = sum_general + (targetClient?.isMarried ? sum_married : sum_single);

  return res;
}

function thirdC(targetClient, targetLoan) {
  if (!targetClient || !targetLoan) return 0;

  let monthlypay = Number(targetLoan?.totalDueForPeriod) === 0 ? 1 : targetLoan?.totalDueForPeriod || 1;
  let bankStatement = Number(targetLoan?.bankstateent || 0);
  let monthlySaving = Number(targetClient?.monthlySaving || 0);

  let babkstmt = (0.025 * bankStatement) / monthlypay;
  let totalMonthlySaving = (0.025 * monthlySaving) / monthlypay;
  const totalsum = babkstmt + totalMonthlySaving;
  return totalsum > 1 ? 25 : totalsum * 25;
}

function fifthC(targetLoan) {
  if (!targetLoan) return 0;

  const sum_car =
    targetLoan?.collateralcar?.length > 0
      ? targetLoan?.collateralcar?.reduce((accum, obj) => {
        if (!obj) return accum;
        return (
          accum +
          ((obj?.insuranceValue + obj?.garageValue) / 2 * obj?.vehiclePerformance + (obj?.manufacturedYear?.multiplierRatio * obj?.carCC * obj?.model.pricePerCc)) / 2
        );
      }, 0)
      : 0;

  const sum_home =
    targetLoan?.collateralhome?.length > 0
      ? targetLoan?.collateralhome?.reduce((accum, home) => {
        return accum + singleHomePrice(home);;
      }, 0)
      : 0;

  const sum_salary =
    targetLoan?.collateralemployee?.length > 0
      ? targetLoan?.collateralemployee?.reduce((accum, obj) => {
        if (!obj) return accum;
        return accum + ((obj?.grossSalary || 0) * 100) / 15;
      }, 0)
      : 0;

  const sum_stock =
    targetLoan?.collateralstock?.length > 0
      ? targetLoan?.collateralstock?.reduce((accum, obj) => {
        if (!obj) return accum;
        return accum + (obj?.priceperstock || 0) * (obj?.stockqty || 0);
      }, 0)
      : 0;

  let total_sum = sum_car + sum_home + sum_salary + sum_stock;

  let calculatedResult =
    total_sum > (targetLoan?.approvedPrincipal || 0) ? 25 : (total_sum / (targetLoan?.approvedPrincipal || 1)) * 25;

  return Number(calculatedResult);
}

module.exports = {
  Sum_Cogs,
  Sum_Operating_Expenses,
  Sum_Interest_Expenses,
  Sum_Tax_Expenses,
  Sum_Personal_Expenses,
  Sum_Incomes,
  Sum_Assets,
  Sum_Liabilities,
  Sum_Capitals,
  Monthly_Basic_Expenses,
  Sum_MaintenanceRelatedExpenses,
  Sum_FinancialExpenses,
  Sum_OtherExpenses,
  calculateIRR,
  DebtServiceCoverageRatio,
  DebtServiceCoverageRatioNew,
  LiquidityRatio,
  NetWorth,
  Debt_To_Asset_Ratio,
  Loan_To_Value_Ratio,
  CapitalEvaluation,
  CharacterEvaluation,
  Debt_To_Income_Ratio,
  min_dir_dscr_irr,
  dir_vs_dscd,
  finalAbilityToPay,
  collateralSum,
  singleCarPrice,
  singleHomePrice,
  firstC,
  thirdC,
  fifthC
};
