import React from "react";
import { Text, View } from "@react-pdf/renderer";

function TblLoanRequest(props) {
    return (
        <View
            style={{
                display: "flex",
                flexDirection: "row",
                fontSize: 9,
                fontFamily: "AmharicFont",
                backgroundColor: props.bgcolor,
            }}
        >
            <View
                style={{
                    borderWidth: 1,
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    // borderColor: "#fff",
                    // borderStyle: "solid",
                    // backgroundColor: "#f8c4ac",
                    width: "60%",
                    paddingLeft: 2,
                    paddingBottom: 1,
                }}
            >
                <Text style={{ textAlign: props.textalign }}>{props.zkey}</Text>
            </View>
            <View
                style={{
                    borderWidth: 1,
                    borderTopWidth: 0,
                    // borderColor: "#fff",
                    // borderStyle: "solid",
                    // color:"#fff",
                    // backgroundColor: "#0e2841",
                    width: "40%",
                    paddingLeft: 2,
                    paddingBottom: 1,
                }}
            >
                <Text>{props.zvalue}</Text>
            </View>
        </View>
    );
}

// #61cbf3 , #83e28e ,  #e8e8e8, #d9d9d9, #ff0000

export default TblLoanRequest